// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.61.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityAuditTrail
 */
export interface ActivityAuditTrail {
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    activityName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    assignee?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    completedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityAuditTrail
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    processBusinessKey?: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    formattedAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Address
     */
    rawAddress?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    streetAddress?: string;
}
/**
 * 
 * @export
 * @interface AddressBorrowerAllowed
 */
export interface AddressBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    formattedAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    postalCode?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AddressBorrowerAllowed
     */
    rawAddress?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    streetAddress?: string;
}
/**
 * 
 * @export
 * @interface AddressInbound
 */
export interface AddressInbound {
    /**
     * 
     * @type {string}
     * @memberof AddressInbound
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInbound
     */
    city: string;
    /**
     * ISO 3166-1 Alpha-2 code
     * @type {string}
     * @memberof AddressInbound
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInbound
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInbound
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInbound
     */
    streetAddress: string;
}
/**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {Address}
     * @memberof Agent
     */
    address?: Address;
    /**
     * 
     * @type {BankAccount}
     * @memberof Agent
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Agent
     */
    contactDetails?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    email: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Agent
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof Agent
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    legalName: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof Agent
     */
    logo?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    taxNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface AgentBorrowerAllowed
 */
export interface AgentBorrowerAllowed {
    /**
     * 
     * @type {AddressBorrowerAllowed}
     * @memberof AgentBorrowerAllowed
     */
    address?: AddressBorrowerAllowed;
    /**
     * 
     * @type {BankAccountBorrowerAllowed}
     * @memberof AgentBorrowerAllowed
     */
    bankAccount?: BankAccountBorrowerAllowed;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AgentBorrowerAllowed
     */
    contactDetails?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AgentBorrowerAllowed
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {Array<FileBorrowerAllowed>}
     * @memberof AgentBorrowerAllowed
     */
    logo?: Array<FileBorrowerAllowed>;
    /**
     * 
     * @type {string}
     * @memberof AgentBorrowerAllowed
     */
    taxNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBorrowerAllowed
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface AgentBrief
 */
export interface AgentBrief {
    /**
     * 
     * @type {object}
     * @memberof AgentBrief
     */
    address?: object;
    /**
     * 
     * @type {object}
     * @memberof AgentBrief
     */
    bankAccount?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AgentBrief
     */
    contactDetails?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof AgentBrief
     */
    email: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AgentBrief
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof AgentBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentBrief
     */
    legalName: string;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof AgentBrief
     */
    logo?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof AgentBrief
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBrief
     */
    taxNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBrief
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriod
 */
export interface AmortisationSchedulePeriod {
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriod
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    number?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AmortisationSchedulePeriod
     */
    paymentPeriod?: boolean;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodBorrowerAllowed
 */
export interface AmortisationSchedulePeriodBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodBorrowerAllowed
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AmortisationSchedulePeriodBorrowerAllowed
     */
    paymentPeriod?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationForm
 */
export interface ApplicationForm {
    /**
     * 
     * @type {Array<object>}
     * @memberof ApplicationForm
     */
    collateralForm?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ApplicationForm
     */
    furtherInformationForm?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasBalanceSheet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasBankStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasCollateral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasFinancialStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasFurtherInformation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasPnlStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasPurpose?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasSupportingDocuments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasUtilityBill?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationFormBorrowerAllowed
 */
export interface ApplicationFormBorrowerAllowed {
    /**
     * 
     * @type {Array<object>}
     * @memberof ApplicationFormBorrowerAllowed
     */
    collateralForm?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ApplicationFormBorrowerAllowed
     */
    furtherInformationForm?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasBalanceSheet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasBankStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasCollateral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasFinancialStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasFurtherInformation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasPnlStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasPurpose?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasSupportingDocuments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasUtilityBill?: boolean;
}
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    bankTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    branch?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    iban?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    routingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    sortCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BankAccountBorrowerAllowed
 */
export interface BankAccountBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    accountHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    bankTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    branch?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    iban?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccountBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    routingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    sortCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBorrowerAllowed
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BankAccountInbound
 */
export interface BankAccountInbound {
    /**
     * 
     * @type {string}
     * @memberof BankAccountInbound
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInbound
     */
    bankTitle?: string;
}
/**
 * 
 * @export
 * @interface BlacklistEntryBorrower
 */
export interface BlacklistEntryBorrower {
    /**
     * 
     * @type {LegalEntity | Person}
     * @memberof BlacklistEntryBorrower
     */
    borrower?: LegalEntity | Person;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntryBorrower
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntryBorrower
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntryBorrower
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntryBorrower
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof BlacklistEntryBorrower
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntryBorrower
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntryBorrower
     */
    reason: string;
}
/**
 * 
 * @export
 * @interface Borrower
 */
export interface Borrower {
    /**
     * 
     * @type {Address}
     * @memberof Borrower
     */
    address?: Address;
    /**
     * 
     * @type {BankAccount}
     * @memberof Borrower
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {boolean}
     * @memberof Borrower
     */
    blacklisted: boolean;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    email?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Borrower
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof Borrower
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    taxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    type?: string;
}
/**
 * Borrower details
 * @export
 * @interface BorrowerLoanManagement
 */
export interface BorrowerLoanManagement {
    /**
     * 
     * @type {AddressInbound}
     * @memberof BorrowerLoanManagement
     */
    address: AddressInbound;
    /**
     * 
     * @type {BankAccountInbound}
     * @memberof BorrowerLoanManagement
     */
    bankAccount: BankAccountInbound;
    /**
     * 
     * @type {string}
     * @memberof BorrowerLoanManagement
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerLoanManagement
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerLoanManagement
     */
    taxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerLoanManagement
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BulkRetryIncidentsResponse
 */
export interface BulkRetryIncidentsResponse {
    /**
     * 
     * @type {number}
     * @memberof BulkRetryIncidentsResponse
     */
    attemptToRetryQty?: number;
}
/**
 * 
 * @export
 * @interface CallResult
 */
export interface CallResult {
    /**
     * 
     * @type {number}
     * @memberof CallResult
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    calledAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof CallResult
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    nextReminderDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    result: string;
}
/**
 * 
 * @export
 * @interface CallResultBrief
 */
export interface CallResultBrief {
    /**
     * 
     * @type {number}
     * @memberof CallResultBrief
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    calledAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof CallResultBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    nextReminderDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    result: string;
}
/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    active?: boolean;
    /**
     * 
     * @type {Agent}
     * @memberof Campaign
     */
    agent?: Agent;
    /**
     * 
     * @type {ApplicationForm}
     * @memberof Campaign
     */
    applicationForm?: ApplicationForm;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    calculationType?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    collectionStrategy: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    daysOverdue?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    disbursementOption: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    giniMachineScoringModel?: string;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    name?: string;
    /**
     * 
     * @type {CampaignParameters}
     * @memberof Campaign
     */
    parameters?: CampaignParameters;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    paymentFrequency?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    rangeOfDaysForFirstPaymentDate?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    scoringModel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    scoringProviders?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    signingOption?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    templateName: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof Campaign
     */
    termPeriodType?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    underwritingDecisionMaking: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    verificationDecisionMaking: string;
}
/**
 * 
 * @export
 * @interface CampaignBorrowerAllowed
 */
export interface CampaignBorrowerAllowed {
    /**
     * 
     * @type {AgentBorrowerAllowed}
     * @memberof CampaignBorrowerAllowed
     */
    agent?: AgentBorrowerAllowed;
    /**
     * 
     * @type {ApplicationFormBorrowerAllowed}
     * @memberof CampaignBorrowerAllowed
     */
    applicationForm?: ApplicationFormBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    calculationType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    collectionStrategy: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBorrowerAllowed
     */
    daysOverdue?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    disbursementOption: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    giniMachineScoringModel?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    name?: string;
    /**
     * 
     * @type {CampaignParametersBorrowerAllowed}
     * @memberof CampaignBorrowerAllowed
     */
    parameters?: CampaignParametersBorrowerAllowed;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignBorrowerAllowed
     */
    paymentFrequency?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CampaignBorrowerAllowed
     */
    rangeOfDaysForFirstPaymentDate?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    scoringModel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignBorrowerAllowed
     */
    scoringProviders?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    signingOption?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    templateName: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    termPeriodType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    underwritingDecisionMaking: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    verificationDecisionMaking: string;
}
/**
 * 
 * @export
 * @interface CampaignBrief
 */
export interface CampaignBrief {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBrief
     */
    active?: boolean;
    /**
     * 
     * @type {AgentBrief}
     * @memberof CampaignBrief
     */
    agent?: AgentBrief;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    calculationType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    collectionStrategy: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBrief
     */
    daysOverdue?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    disbursementOption: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    giniMachineScoringModel?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    name?: string;
    /**
     * 
     * @type {CampaignParametersBrief}
     * @memberof CampaignBrief
     */
    parameters?: CampaignParametersBrief;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignBrief
     */
    paymentFrequency?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CampaignBrief
     */
    rangeOfDaysForFirstPaymentDate?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    scoringModel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignBrief
     */
    scoringProviders?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    signingOption?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    templateName: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof CampaignBrief
     */
    termPeriodType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    underwritingDecisionMaking: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    verificationDecisionMaking: string;
}
/**
 * 
 * @export
 * @interface CampaignParameters
 */
export interface CampaignParameters {
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    amountSelectionStep: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof CampaignParameters
     */
    defaultPaymentFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    interestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    minTerm: number;
    /**
     * 
     * @type {Array<TermOption>}
     * @memberof CampaignParameters
     */
    termOptions?: Array<TermOption>;
    /**
     * 
     * @type {string}
     * @memberof CampaignParameters
     */
    termSelectionMode: string;
}
/**
 * 
 * @export
 * @interface CampaignParametersBorrowerAllowed
 */
export interface CampaignParametersBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBorrowerAllowed
     */
    amountSelectionStep: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof CampaignParametersBorrowerAllowed
     */
    defaultPaymentFrequency?: string;
    /**
     * 
     * @type {Array<TermOptionBorrowerAllowed>}
     * @memberof CampaignParametersBorrowerAllowed
     */
    termOptions?: Array<TermOptionBorrowerAllowed>;
    /**
     * 
     * @type {string}
     * @memberof CampaignParametersBorrowerAllowed
     */
    termSelectionMode: string;
}
/**
 * 
 * @export
 * @interface CampaignParametersBrief
 */
export interface CampaignParametersBrief {
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    amountSelectionStep: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof CampaignParametersBrief
     */
    defaultPaymentFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    interestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    minTerm: number;
    /**
     * 
     * @type {Array<TermOptionBrief>}
     * @memberof CampaignParametersBrief
     */
    termOptions?: Array<TermOptionBrief>;
    /**
     * 
     * @type {string}
     * @memberof CampaignParametersBrief
     */
    termSelectionMode: string;
}
/**
 * 
 * @export
 * @interface Collateral
 */
export interface Collateral {
    /**
     * 
     * @type {number}
     * @memberof Collateral
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Collateral
     */
    item?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof Collateral
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface CollateralBorrowerAllowed
 */
export interface CollateralBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof CollateralBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CollateralBorrowerAllowed
     */
    item?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof CollateralBorrowerAllowed
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface Collection
 */
export interface Collection {
    /**
     * 
     * @type {Array<CallResult>}
     * @memberof Collection
     */
    callResults?: Array<CallResult>;
    /**
     * 
     * @type {number}
     * @memberof Collection
     */
    id?: number;
    /**
     * 
     * @type {Loan}
     * @memberof Collection
     */
    loan: Loan;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    status: string;
}
/**
 * 
 * @export
 * @interface CollectionBrief
 */
export interface CollectionBrief {
    /**
     * 
     * @type {Array<CallResultBrief>}
     * @memberof CollectionBrief
     */
    callResults?: Array<CallResultBrief>;
    /**
     * 
     * @type {number}
     * @memberof CollectionBrief
     */
    id?: number;
    /**
     * 
     * @type {LoanBrief}
     * @memberof CollectionBrief
     */
    loan: LoanBrief;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    status: string;
}
/**
 * 
 * @export
 * @interface DeploymentRepresentation
 */
export interface DeploymentRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    deploymentTime: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    tenantId?: string;
}
/**
 * 
 * @export
 * @interface FileBorrowerAllowed
 */
export interface FileBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof FileBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileBorrowerAllowed
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBorrowerAllowed
     */
    originalName?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBorrowerAllowed
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface FileBrief
 */
export interface FileBrief {
    /**
     * 
     * @type {number}
     * @memberof FileBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    originalName?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface FileUploadResponse
 */
export interface FileUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof FileUploadResponse
     */
    persistedFileName?: string;
}
/**
 * 
 * @export
 * @interface FinancialFigures
 */
export interface FinancialFigures {
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    ebitda: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    grossProfit: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    netDebt: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    netIncome: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    operatingProfit: number;
    /**
     * 
     * @type {ProfitabilityRatios}
     * @memberof FinancialFigures
     */
    profitabilityRatios?: ProfitabilityRatios;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    totalAssets: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    totalLiabilities: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    totalSales: number;
}
/**
 * 
 * @export
 * @interface FinancialFiguresBorrowerAllowed
 */
export interface FinancialFiguresBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    ebitda: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    grossProfit: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    netDebt: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    netIncome: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    operatingProfit: number;
    /**
     * 
     * @type {ProfitabilityRatiosBorrowerAllowed}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    profitabilityRatios?: ProfitabilityRatiosBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    totalAssets: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    totalLiabilities: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBorrowerAllowed
     */
    totalSales: number;
}
/**
 * 
 * @export
 * @interface FinancialFiguresBrief
 */
export interface FinancialFiguresBrief {
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    ebitda: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    grossProfit: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    netDebt: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    netIncome: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    operatingProfit: number;
    /**
     * 
     * @type {ProfitabilityRatiosBrief}
     * @memberof FinancialFiguresBrief
     */
    profitabilityRatios?: ProfitabilityRatiosBrief;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    totalAssets: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    totalLiabilities: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    totalSales: number;
}
/**
 * 
 * @export
 * @interface GiniMachineScoringModel
 */
export interface GiniMachineScoringModel {
    /**
     * 
     * @type {string}
     * @memberof GiniMachineScoringModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GiniMachineScoringModel
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IdDocument
 */
export interface IdDocument {
    /**
     * 
     * @type {Array<any>}
     * @memberof IdDocument
     */
    attachments?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    expirationDate: string;
    /**
     * 
     * @type {number}
     * @memberof IdDocument
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface IdDocumentLoanManagement
 */
export interface IdDocumentLoanManagement {
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanManagement
     */
    expirationDate: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanManagement
     */
    issueDate: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanManagement
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanManagement
     */
    type: IdDocumentLoanManagementTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum IdDocumentLoanManagementTypeEnum {
    PASSPORT = 'PASSPORT',
    IDCARD = 'ID_CARD',
    DRIVERLICENSE = 'DRIVER_LICENSE'
}

/**
 * 
 * @export
 * @interface IdDocumentLoanOrigination
 */
export interface IdDocumentLoanOrigination {
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanOrigination
     */
    expirationDate: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanOrigination
     */
    issueDate: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanOrigination
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentLoanOrigination
     */
    type: IdDocumentLoanOriginationTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum IdDocumentLoanOriginationTypeEnum {
    PASSPORT = 'PASSPORT',
    IDCARD = 'ID_CARD',
    DRIVERLICENSE = 'DRIVER_LICENSE'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * The file content to store
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * List of Balance sheet files
     * @type {Array}
     * @memberof InlineObject1
     */
    balanceSheet: Array;
    /**
     * URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
     * @type {string}
     * @memberof InlineObject1
     */
    callbackUrl?: string;
    /**
     * List of files of the document identifying the representative of the legal entity
     * @type {Array}
     * @memberof InlineObject1
     */
    idDocument: Array;
    /**
     * List of Profit and loss statement files
     * @type {Array}
     * @memberof InlineObject1
     */
    pnlStatement: Array;
    /**
     * 
     * @type {RequestLegalEntityLoanOrigination}
     * @memberof InlineObject1
     */
    request: RequestLegalEntityLoanOrigination;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
     * @type {string}
     * @memberof InlineObject2
     */
    callbackUrl?: string;
    /**
     * List of the identification document\'s files
     * @type {Array}
     * @memberof InlineObject2
     */
    idDocument: Array;
    /**
     * 
     * @type {RequestPersonLoanOrigination}
     * @memberof InlineObject2
     */
    request: RequestPersonLoanOrigination;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * List of Balance sheet files
     * @type {Array}
     * @memberof InlineObject3
     */
    balanceSheet: Array;
    /**
     * URL for sending a callback with a processed loan application
     * @type {string}
     * @memberof InlineObject3
     */
    callbackUrl: string;
    /**
     * List of files of the document identifying the representative of the legal entity
     * @type {Array}
     * @memberof InlineObject3
     */
    idDocument: Array;
    /**
     * List of Profit and loss statement files
     * @type {Array}
     * @memberof InlineObject3
     */
    pnlStatement: Array;
    /**
     * 
     * @type {RequestLegalEntityLoanOrigination}
     * @memberof InlineObject3
     */
    request: RequestLegalEntityLoanOrigination;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * URL for sending a callback with a processed loan application
     * @type {string}
     * @memberof InlineObject4
     */
    callbackUrl: string;
    /**
     * List of the identification document\'s files
     * @type {Array}
     * @memberof InlineObject4
     */
    idDocument: Array;
    /**
     * 
     * @type {RequestPersonLoanOrigination}
     * @memberof InlineObject4
     */
    request: RequestPersonLoanOrigination;
}
/**
 * 
 * @export
 * @interface KycResults
 */
export interface KycResults {
    /**
     * 
     * @type {string}
     * @memberof KycResults
     */
    createdAt?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KycResults
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof KycResults
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KycResults
     */
    providerName?: string;
}
/**
 * 
 * @export
 * @interface Lead
 */
export interface Lead {
    /**
     * 
     * @type {Agent}
     * @memberof Lead
     */
    agent?: Agent;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface LeadBrief
 */
export interface LeadBrief {
    /**
     * 
     * @type {AgentBrief}
     * @memberof LeadBrief
     */
    agent?: AgentBrief;
    /**
     * 
     * @type {string}
     * @memberof LeadBrief
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LeadBrief
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LeadBrief
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadBrief
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LeadBrief
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface LegalEntity
 */
export interface LegalEntity extends Borrower {
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    corporateType?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LegalEntity
     */
    financialInformation?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    legalName?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntity
     */
    monthsInOperation?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    registrationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    registrationNumber?: string;
    /**
     * 
     * @type {Representative}
     * @memberof LegalEntity
     */
    representative?: Representative;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface LegalEntityAllOf
 */
export interface LegalEntityAllOf {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAllOf
     */
    corporateType?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LegalEntityAllOf
     */
    financialInformation?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAllOf
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAllOf
     */
    legalName?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityAllOf
     */
    monthsInOperation?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAllOf
     */
    registrationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAllOf
     */
    registrationNumber?: string;
    /**
     * 
     * @type {Representative}
     * @memberof LegalEntityAllOf
     */
    representative?: Representative;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAllOf
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface LegalEntityLoanManagement
 */
export interface LegalEntityLoanManagement {
    /**
     * 
     * @type {AddressInbound}
     * @memberof LegalEntityLoanManagement
     */
    address: AddressInbound;
    /**
     * 
     * @type {BankAccountInbound}
     * @memberof LegalEntityLoanManagement
     */
    bankAccount: BankAccountInbound;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    taxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    type?: string;
    /**
     * Marketing legal entity name
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    registrationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagement
     */
    registrationNumber: string;
}
/**
 * 
 * @export
 * @interface LegalEntityLoanManagementAllOf
 */
export interface LegalEntityLoanManagementAllOf {
    /**
     * Marketing legal entity name
     * @type {string}
     * @memberof LegalEntityLoanManagementAllOf
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagementAllOf
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagementAllOf
     */
    registrationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanManagementAllOf
     */
    registrationNumber?: string;
}
/**
 * Borrower details
 * @export
 * @interface LegalEntityLoanOrigination
 */
export interface LegalEntityLoanOrigination {
    /**
     * 
     * @type {AddressInbound}
     * @memberof LegalEntityLoanOrigination
     */
    address: AddressInbound;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityLoanOrigination
     */
    annualRevenue?: number;
    /**
     * 
     * @type {BankAccountInbound}
     * @memberof LegalEntityLoanOrigination
     */
    bankAccount: BankAccountInbound;
    /**
     * Available values: [ Sole proprietorship, Limited Liability Company (LLC), S-Corp, C-Corp ]
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    corporateType?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    email: string;
    /**
     * Marketing legal entity name
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    fullName: string;
    /**
     * Available values: [ Agriculture; Plantations; Other rural sectors, Basic metal production, Chemical industries, Commerce, Construction, Education, Financial services, Food; Drink; Tobacco, Forestry; Wood; Pulp and paper, Health services, Hotels; Tourism; Catering, Information technology, Mining, Mechanical and electrical engineering, Media; Culture; Graphical, Postal and telecommunications services, Public service, Real estate; Rental; Leasing, Repair and maintenance, Shipping; Ports; Fisheries; Inland waterways, Textiles; Clothing; Leather; Footwear, Transport, Utilities ]
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    legalName: string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityLoanOrigination
     */
    monthsInOperation?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    registrationDate: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    registrationNumber: string;
    /**
     * 
     * @type {RepresentativeLoanOrigination}
     * @memberof LegalEntityLoanOrigination
     */
    representative: RepresentativeLoanOrigination;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityLoanOrigination
     */
    taxNumber?: string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    templated?: boolean;
}
/**
 * 
 * @export
 * @interface Loan
 */
export interface Loan {
    /**
     * 
     * @type {boolean}
     * @memberof Loan
     */
    active: boolean;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof Loan
     */
    actualSnapshot?: LoanSnapshot;
    /**
     * 
     * @type {LegalEntity | Person}
     * @memberof Loan
     */
    borrower?: LegalEntity | Person;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    issueDate: string;
    /**
     * 
     * @type {LoanApplicationBorrower}
     * @memberof Loan
     */
    loanApplication: LoanApplicationBorrower;
    /**
     * 
     * @type {LoanProduct}
     * @memberof Loan
     */
    loanProduct?: LoanProduct;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    maturityDate: string;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    nextPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    writeOffReason?: string;
}
/**
 * 
 * @export
 * @interface LoanApplication
 */
export interface LoanApplication {
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    active?: boolean;
    /**
     * 
     * @type {Agent}
     * @memberof LoanApplication
     */
    agent?: Agent;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriod>}
     * @memberof LoanApplication
     */
    amortisationSchedule?: Array<AmortisationSchedulePeriod>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    approvedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    attachments?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    bankAccountVerification?: { [key: string]: object; };
    /**
     * 
     * @type {Borrower}
     * @memberof LoanApplication
     */
    borrower?: Borrower;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    calculationType?: string;
    /**
     * 
     * @type {Campaign}
     * @memberof LoanApplication
     */
    campaign?: Campaign;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    cancelCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    closedAt?: string;
    /**
     * 
     * @type {Collateral}
     * @memberof LoanApplication
     */
    collateral?: Collateral;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    complianceInfo?: { [key: string]: object; };
    /**
     * 
     * @type {any}
     * @memberof LoanApplication
     */
    contract?: any;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    contractOtpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    contractSignedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    creditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    declinedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {FinancialFigures}
     * @memberof LoanApplication
     */
    financialFigures?: FinancialFigures;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    firstPaymentDate?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    furtherInformation?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    interestRateApprovedPct?: number;
    /**
     * 
     * @type {LoanProduct}
     * @memberof LoanApplication
     */
    loanProduct?: LoanProduct;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    ltvPct?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    number?: string;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplication
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    requestedAt: string;
    /**
     * 
     * @type {Scoring}
     * @memberof LoanApplication
     */
    scoring?: Scoring;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplication
     */
    stopFactors?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    termApproved?: number;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplication
     */
    termPeriodType?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    termRequested: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    totalToPay?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    validationResult?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface LoanApplicationBorrower
 */
export interface LoanApplicationBorrower {
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrower
     */
    active?: boolean;
    /**
     * 
     * @type {Agent}
     * @memberof LoanApplicationBorrower
     */
    agent?: Agent;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriod>}
     * @memberof LoanApplicationBorrower
     */
    amortisationSchedule?: Array<AmortisationSchedulePeriod>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    approvedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrower
     */
    attachments?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrower
     */
    bankAccountVerification?: { [key: string]: object; };
    /**
     * 
     * @type {LegalEntity | Person}
     * @memberof LoanApplicationBorrower
     */
    borrower?: LegalEntity | Person;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    calculationType?: string;
    /**
     * 
     * @type {Campaign}
     * @memberof LoanApplicationBorrower
     */
    campaign?: Campaign;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    cancelCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    closedAt?: string;
    /**
     * 
     * @type {Collateral}
     * @memberof LoanApplicationBorrower
     */
    collateral?: Collateral;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrower
     */
    complianceInfo?: { [key: string]: object; };
    /**
     * 
     * @type {any}
     * @memberof LoanApplicationBorrower
     */
    contract?: any;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    contractOtpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    contractSignedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    creditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrower
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    declinedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrower
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {FinancialFigures}
     * @memberof LoanApplicationBorrower
     */
    financialFigures?: FinancialFigures;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    firstPaymentDate?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrower
     */
    furtherInformation?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    interestRateApprovedPct?: number;
    /**
     * 
     * @type {LoanProduct}
     * @memberof LoanApplicationBorrower
     */
    loanProduct?: LoanProduct;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    ltvPct?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    number?: string;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    requestedAt: string;
    /**
     * 
     * @type {Scoring}
     * @memberof LoanApplicationBorrower
     */
    scoring?: Scoring;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplicationBorrower
     */
    stopFactors?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    termApproved?: number;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBorrower
     */
    termPeriodType?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    termRequested: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrower
     */
    totalToPay?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrower
     */
    validationResult?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface LoanApplicationBorrowerAllowed
 */
export interface LoanApplicationBorrowerAllowed {
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerAllowed
     */
    active?: boolean;
    /**
     * 
     * @type {AgentBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    agent?: AgentBorrowerAllowed;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodBorrowerAllowed>}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amortisationSchedule?: Array<AmortisationSchedulePeriodBorrowerAllowed>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    approvedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerAllowed
     */
    attachments?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerAllowed
     */
    bankAccountVerification?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    calculationType?: string;
    /**
     * 
     * @type {CampaignBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    campaign?: CampaignBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    cancelCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    closedAt?: string;
    /**
     * 
     * @type {CollateralBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    collateral?: CollateralBorrowerAllowed;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerAllowed
     */
    complianceInfo?: { [key: string]: object; };
    /**
     * 
     * @type {FileBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    contract?: FileBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    contractOtpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    contractSignedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    creditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerAllowed
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    declinedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerAllowed
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {FinancialFiguresBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    financialFigures?: FinancialFiguresBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    firstPaymentDate?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerAllowed
     */
    furtherInformation?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    interestRateApprovedPct?: number;
    /**
     * 
     * @type {LoanProductBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    loanProduct?: LoanProductBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    ltvPct?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    number?: string;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    requestedAt: string;
    /**
     * 
     * @type {ScoringBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    scoring?: ScoringBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplicationBorrowerAllowed
     */
    stopFactors?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    termApproved?: number;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    termPeriodType?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    termRequested: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    totalToPay?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerAllowed
     */
    validationResult?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface LoanApplicationBorrowerBorrowerAllowed
 */
export interface LoanApplicationBorrowerBorrowerAllowed {
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    active?: boolean;
    /**
     * 
     * @type {AgentBorrowerAllowed}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    agent?: AgentBorrowerAllowed;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodBorrowerAllowed>}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    amortisationSchedule?: Array<AmortisationSchedulePeriodBorrowerAllowed>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    approvedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    attachments?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    bankAccountVerification?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    calculationType?: string;
    /**
     * 
     * @type {CampaignBorrowerAllowed}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    campaign?: CampaignBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    cancelCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    closedAt?: string;
    /**
     * 
     * @type {CollateralBorrowerAllowed}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    collateral?: CollateralBorrowerAllowed;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    complianceInfo?: { [key: string]: object; };
    /**
     * 
     * @type {FileBorrowerAllowed}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    contract?: FileBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    contractOtpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    contractSignedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    creditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    declinedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {FinancialFiguresBorrowerAllowed}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    financialFigures?: FinancialFiguresBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    firstPaymentDate?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    furtherInformation?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    interestRateApprovedPct?: number;
    /**
     * 
     * @type {LoanProductBorrowerAllowed}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    loanProduct?: LoanProductBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    ltvPct?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    number?: string;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    requestedAt: string;
    /**
     * 
     * @type {ScoringBorrowerAllowed}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    scoring?: ScoringBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    stopFactors?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    termApproved?: number;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    termPeriodType?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    termRequested: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    totalToPay?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBorrowerAllowed
     */
    validationResult?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface LoanApplicationBorrowerBrief
 */
export interface LoanApplicationBorrowerBrief {
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerBrief
     */
    active?: boolean;
    /**
     * 
     * @type {AgentBrief}
     * @memberof LoanApplicationBorrowerBrief
     */
    agent?: AgentBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    approvedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBrief
     */
    bankAccountVerification?: { [key: string]: object; };
    /**
     * 
     * @type {LegalEntity | Person}
     * @memberof LoanApplicationBorrowerBrief
     */
    borrower?: LegalEntity | Person;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    calculationType?: string;
    /**
     * 
     * @type {CampaignBrief}
     * @memberof LoanApplicationBorrowerBrief
     */
    campaign?: CampaignBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    cancelCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    closedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    contractOtpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    contractSignedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    creditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerBrief
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    declinedBy?: string;
    /**
     * 
     * @type {FinancialFiguresBrief}
     * @memberof LoanApplicationBorrowerBrief
     */
    financialFigures?: FinancialFiguresBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    interestRateApprovedPct?: number;
    /**
     * 
     * @type {LoanProductBrief}
     * @memberof LoanApplicationBorrowerBrief
     */
    loanProduct?: LoanProductBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    ltvPct?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    requestedAt: string;
    /**
     * 
     * @type {ScoringBrief}
     * @memberof LoanApplicationBorrowerBrief
     */
    scoring?: ScoringBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplicationBorrowerBrief
     */
    stopFactors?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    termApproved?: number;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBorrowerBrief
     */
    termPeriodType?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    termRequested: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerBrief
     */
    totalToPay?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBorrowerBrief
     */
    validationResult?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface LoanBorrowerAllowed
 */
export interface LoanBorrowerAllowed {
    /**
     * 
     * @type {boolean}
     * @memberof LoanBorrowerAllowed
     */
    active: boolean;
    /**
     * 
     * @type {LoanSnapshotBorrowerAllowed}
     * @memberof LoanBorrowerAllowed
     */
    actualSnapshot?: LoanSnapshotBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    issueDate: string;
    /**
     * 
     * @type {LoanApplicationBorrowerBorrowerAllowed}
     * @memberof LoanBorrowerAllowed
     */
    loanApplication: LoanApplicationBorrowerBorrowerAllowed;
    /**
     * 
     * @type {LoanProductBorrowerAllowed}
     * @memberof LoanBorrowerAllowed
     */
    loanProduct?: LoanProductBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    maturityDate: string;
    /**
     * 
     * @type {number}
     * @memberof LoanBorrowerAllowed
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    nextPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    writeOffReason?: string;
}
/**
 * 
 * @export
 * @interface LoanBrief
 */
export interface LoanBrief {
    /**
     * 
     * @type {boolean}
     * @memberof LoanBrief
     */
    active: boolean;
    /**
     * 
     * @type {LoanSnapshotBrief}
     * @memberof LoanBrief
     */
    actualSnapshot?: LoanSnapshotBrief;
    /**
     * 
     * @type {LegalEntity | Person}
     * @memberof LoanBrief
     */
    borrower?: LegalEntity | Person;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    issueDate: string;
    /**
     * 
     * @type {LoanApplicationBorrowerBrief}
     * @memberof LoanBrief
     */
    loanApplication: LoanApplicationBorrowerBrief;
    /**
     * 
     * @type {LoanProductBrief}
     * @memberof LoanBrief
     */
    loanProduct?: LoanProductBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    maturityDate: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    writeOffReason?: string;
}
/**
 * 
 * @export
 * @interface LoanOperation
 */
export interface LoanOperation {
    /**
     * 
     * @type {number}
     * @memberof LoanOperation
     */
    amount: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof LoanOperation
     */
    attachments?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanOperation
     */
    id?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanOperation
     */
    loan: Loan;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof LoanOperation
     */
    snapshot: LoanSnapshot;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    type: string;
}
/**
 * 
 * @export
 * @interface LoanOperationRepresentation
 */
export interface LoanOperationRepresentation {
    /**
     * 
     * @type {number}
     * @memberof LoanOperationRepresentation
     */
    amount: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof LoanOperationRepresentation
     */
    attachments?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanOperationRepresentation
     */
    id?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanOperationRepresentation
     */
    loan?: Loan;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof LoanOperationRepresentation
     */
    snapshot: LoanSnapshot;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    type: string;
}
/**
 * 
 * @export
 * @interface LoanProduct
 */
export interface LoanProduct {
    /**
     * 
     * @type {boolean}
     * @memberof LoanProduct
     */
    active: boolean;
    /**
     * 
     * @type {Campaign}
     * @memberof LoanProduct
     */
    campaign: Campaign;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    defaultInterestRatePct: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    earlyRepaymentFeeRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    establishmentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    graceInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    gracePeriodDays: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    maxInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    minInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    minTerm: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    modifiedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    overduePenalty: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProduct
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    serviceFeeRatePct?: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProduct
     */
    termPeriodType: string;
}
/**
 * 
 * @export
 * @interface LoanProductBorrowerAllowed
 */
export interface LoanProductBorrowerAllowed {
    /**
     * 
     * @type {CampaignBorrowerAllowed}
     * @memberof LoanProductBorrowerAllowed
     */
    campaign: CampaignBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    defaultInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    earlyRepaymentFeeRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    establishmentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    graceInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    gracePeriodDays: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    maxInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    minInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    minTerm: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    modifiedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    overduePenalty: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    serviceFeeRatePct?: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    termPeriodType: string;
}
/**
 * 
 * @export
 * @interface LoanProductBrief
 */
export interface LoanProductBrief {
    /**
     * 
     * @type {boolean}
     * @memberof LoanProductBrief
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    maxInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    minInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    minTerm: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    modifiedBy?: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBrief
     */
    paymentFrequency?: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBrief
     */
    termPeriodType: string;
}
/**
 * Loan product that the loan applied for
 * @export
 * @interface LoanProductLoanManagement
 */
export interface LoanProductLoanManagement {
    /**
     * Type of calculation on which the amortisation schedule will be calculated.
     * @type {string}
     * @memberof LoanProductLoanManagement
     */
    calculationType: LoanProductLoanManagementCalculationTypeEnum;
    /**
     * Product name.
     * @type {string}
     * @memberof LoanProductLoanManagement
     */
    code: string;
    /**
     * The name of collection strategy
     * @type {string}
     * @memberof LoanProductLoanManagement
     */
    collectionStrategy: LoanProductLoanManagementCollectionStrategyEnum;
    /**
     * Days in overdue before collection starts. Not specified for \'None\' collection strategy
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    daysOverdue?: number;
    /**
     * Default interest rate measured as a percentage. Used when a loan is overdue
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    defaultInterestRatePct: number;
    /**
     * Disbursement option on which the disbursement provider will be selected.
     * @type {string}
     * @memberof LoanProductLoanManagement
     */
    disbursementOption: LoanProductLoanManagementDisbursementOptionEnum;
    /**
     * The fee that is charged if the payment is repaid before the scheduled payment date, measured as a percentage.
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    earlyRepaymentFeeRatePct: number;
    /**
     * The establishment fee that will be charged before the loan is disbursed, measured as an absolute value
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    establishmentFee: number;
    /**
     * Interest rate during the grace period, measured as a percentage.
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    graceInterestRatePct: number;
    /**
     * Days in grace period.
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    gracePeriodDays: number;
    /**
     * The penalty that is charged when a loan becomes overdue.
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    overduePenalty: number;
    /**
     * Frequency with which the payment will be made. Value is allowed only for \'Annuity instalment\' and \'Linear principal repayment instalment\' calculation types
     * @type {string}
     * @memberof LoanProductLoanManagement
     */
    paymentFrequency?: LoanProductLoanManagementPaymentFrequencyEnum;
    /**
     * The service fee that will be charged before the loan is disbursed, measured as a percentage
     * @type {number}
     * @memberof LoanProductLoanManagement
     */
    serviceFeeRatePct: number;
    /**
     * The unit of measure in which the loan term is set. \'DAYS\' value is allowed only for \'PDL\' calculation type
     * @type {string}
     * @memberof LoanProductLoanManagement
     */
    termPeriodType: LoanProductLoanManagementTermPeriodTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanProductLoanManagementCalculationTypeEnum {
    PDL = 'PDL',
    ANNUITYINSTALLMENT = 'ANNUITY_INSTALLMENT',
    LINEARPRINCIPALREPAYMENT = 'LINEAR_PRINCIPAL_REPAYMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum LoanProductLoanManagementCollectionStrategyEnum {
    NONE = 'NONE',
    DEFAULT = 'DEFAULT'
}
/**
    * @export
    * @enum {string}
    */
export enum LoanProductLoanManagementDisbursementOptionEnum {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC'
}
/**
    * @export
    * @enum {string}
    */
export enum LoanProductLoanManagementPaymentFrequencyEnum {
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY'
}
/**
    * @export
    * @enum {string}
    */
export enum LoanProductLoanManagementTermPeriodTypeEnum {
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    MONTHS = 'MONTHS',
    YEARS = 'YEARS'
}

/**
 * 
 * @export
 * @interface LoanSnapshot
 */
export interface LoanSnapshot {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    daysInOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    defaultInterestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    earlyRepaymentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    fullRepaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    interestOverdue?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanSnapshot
     */
    loan?: Loan;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    outstandingLtvPct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    overdueAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    overdueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    penalties?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    technicalAccount?: number;
}
/**
 * 
 * @export
 * @interface LoanSnapshotBorrowerAllowed
 */
export interface LoanSnapshotBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    daysInOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    defaultInterestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    earlyRepaymentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    fullRepaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    interestOverdue?: number;
    /**
     * 
     * @type {LoanBorrowerAllowed}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    loan?: LoanBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    outstandingLtvPct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    overdueAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    overdueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    penalties?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    technicalAccount?: number;
}
/**
 * 
 * @export
 * @interface LoanSnapshotBrief
 */
export interface LoanSnapshotBrief {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    daysInOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    defaultInterestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    earlyRepaymentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    fullRepaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    interestOverdue?: number;
    /**
     * 
     * @type {LoanBrief}
     * @memberof LoanSnapshotBrief
     */
    loan?: LoanBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    outstandingLtvPct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    overdueAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    overdueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    penalties?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    technicalAccount?: number;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    originalName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface PageActivityAuditTrail
 */
export interface PageActivityAuditTrail {
    /**
     * 
     * @type {Array<ActivityAuditTrail>}
     * @memberof PageActivityAuditTrail
     */
    content?: Array<ActivityAuditTrail>;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageActivityAuditTrail
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageActivityAuditTrail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageAgentBrief
 */
export interface PageAgentBrief {
    /**
     * 
     * @type {Array<AgentBrief>}
     * @memberof PageAgentBrief
     */
    content?: Array<AgentBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageAgentBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAgentBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAgentBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageAgentBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAgentBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageAgentBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageAgentBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageAgentBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageAgentBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAgentBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageBorrowerBrief
 */
export interface PageBorrowerBrief {
    /**
     * 
     * @type {Array<LegalEntity | Person>}
     * @memberof PageBorrowerBrief
     */
    content?: Array<LegalEntity | Person>;
    /**
     * 
     * @type {boolean}
     * @memberof PageBorrowerBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBorrowerBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBorrowerBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageBorrowerBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageBorrowerBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageCampaignBrief
 */
export interface PageCampaignBrief {
    /**
     * 
     * @type {Array<CampaignBrief>}
     * @memberof PageCampaignBrief
     */
    content?: Array<CampaignBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageCampaignBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageCampaignBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageCollectionBrief
 */
export interface PageCollectionBrief {
    /**
     * 
     * @type {Array<CollectionBrief>}
     * @memberof PageCollectionBrief
     */
    content?: Array<CollectionBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageCollectionBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageCollectionBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLeadBrief
 */
export interface PageLeadBrief {
    /**
     * 
     * @type {Array<LeadBrief>}
     * @memberof PageLeadBrief
     */
    content?: Array<LeadBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLeadBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLeadBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLeadBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLeadBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLeadBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLeadBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLeadBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLeadBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLeadBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLeadBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanApplicationBorrowerBorrowerAllowed
 */
export interface PageLoanApplicationBorrowerBorrowerAllowed {
    /**
     * 
     * @type {Array<LoanApplicationBorrowerBorrowerAllowed>}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    content?: Array<LoanApplicationBorrowerBorrowerAllowed>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBorrowerAllowed}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    pageable?: PageableObjectBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    size?: number;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBorrowerAllowed
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanApplicationBorrowerBrief
 */
export interface PageLoanApplicationBorrowerBrief {
    /**
     * 
     * @type {Array<LoanApplicationBorrowerBrief>}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    content?: Array<LoanApplicationBorrowerBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanBorrowerAllowed
 */
export interface PageLoanBorrowerAllowed {
    /**
     * 
     * @type {Array<LoanBorrowerAllowed>}
     * @memberof PageLoanBorrowerAllowed
     */
    content?: Array<LoanBorrowerAllowed>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBorrowerAllowed
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBorrowerAllowed
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBorrowerAllowed}
     * @memberof PageLoanBorrowerAllowed
     */
    pageable?: PageableObjectBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    size?: number;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageLoanBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanBrief
 */
export interface PageLoanBrief {
    /**
     * 
     * @type {Array<LoanBrief>}
     * @memberof PageLoanBrief
     */
    content?: Array<LoanBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanOperation
 */
export interface PageLoanOperation {
    /**
     * 
     * @type {Array<LoanOperation>}
     * @memberof PageLoanOperation
     */
    content?: Array<LoanOperation>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanOperation
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLoanOperation
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanProduct
 */
export interface PageLoanProduct {
    /**
     * 
     * @type {Array<LoanProduct>}
     * @memberof PageLoanProduct
     */
    content?: Array<LoanProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanProduct
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLoanProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerBorrower
 */
export interface PageRevisionIntegerBorrower {
    /**
     * 
     * @type {Array<RevisionIntegerBorrower>}
     * @memberof PageRevisionIntegerBorrower
     */
    content?: Array<RevisionIntegerBorrower>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerBorrower
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerBorrower
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerBorrower
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerBorrower
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerBorrower
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerLoanProduct
 */
export interface PageRevisionIntegerLoanProduct {
    /**
     * 
     * @type {Array<RevisionIntegerLoanProduct>}
     * @memberof PageRevisionIntegerLoanProduct
     */
    content?: Array<RevisionIntegerLoanProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerLoanProduct
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerLoanProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageTaskRepresentation
 */
export interface PageTaskRepresentation {
    /**
     * 
     * @type {Array<TaskRepresentation>}
     * @memberof PageTaskRepresentation
     */
    content?: Array<TaskRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTaskRepresentation
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageTaskRepresentation
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageTransactionBorrowerAllowed
 */
export interface PageTransactionBorrowerAllowed {
    /**
     * 
     * @type {Array<TransactionBorrowerAllowed>}
     * @memberof PageTransactionBorrowerAllowed
     */
    content?: Array<TransactionBorrowerAllowed>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBorrowerAllowed
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBorrowerAllowed
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBorrowerAllowed
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBorrowerAllowed}
     * @memberof PageTransactionBorrowerAllowed
     */
    pageable?: PageableObjectBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBorrowerAllowed
     */
    size?: number;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageTransactionBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBorrowerAllowed
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBorrowerAllowed
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageTransactionBrief
 */
export interface PageTransactionBrief {
    /**
     * 
     * @type {Array<TransactionBrief>}
     * @memberof PageTransactionBrief
     */
    content?: Array<TransactionBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageTransactionBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageTransactionBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageUser
 */
export interface PageUser {
    /**
     * 
     * @type {Array<User>}
     * @memberof PageUser
     */
    content?: Array<User>;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageUser
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageUser
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObjectBorrowerAllowed
 */
export interface PageableObjectBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBorrowerAllowed
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBorrowerAllowed
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBorrowerAllowed
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBorrowerAllowed
     */
    paged?: boolean;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageableObjectBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBorrowerAllowed
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObjectBrief
 */
export interface PageableObjectBrief {
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBrief
     */
    paged?: boolean;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageableObjectBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBrief
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface Person
 */
export interface Person extends Borrower {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    citizenship?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    currentAge?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Person
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    gender?: string;
    /**
     * 
     * @type {IdDocument}
     * @memberof Person
     */
    idDocument?: IdDocument;
    /**
     * 
     * @type {KycResults}
     * @memberof Person
     */
    kycResults?: KycResults;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    middleName?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    numberOfDependents?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface PersonAllOf
 */
export interface PersonAllOf {
    /**
     * 
     * @type {string}
     * @memberof PersonAllOf
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAllOf
     */
    citizenship?: string;
    /**
     * 
     * @type {number}
     * @memberof PersonAllOf
     */
    currentAge?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PersonAllOf
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof PersonAllOf
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAllOf
     */
    gender?: string;
    /**
     * 
     * @type {IdDocument}
     * @memberof PersonAllOf
     */
    idDocument?: IdDocument;
    /**
     * 
     * @type {KycResults}
     * @memberof PersonAllOf
     */
    kycResults?: KycResults;
    /**
     * 
     * @type {string}
     * @memberof PersonAllOf
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAllOf
     */
    middleName?: string;
    /**
     * 
     * @type {number}
     * @memberof PersonAllOf
     */
    numberOfDependents?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonAllOf
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface PersonLoanManagement
 */
export interface PersonLoanManagement {
    /**
     * 
     * @type {AddressInbound}
     * @memberof PersonLoanManagement
     */
    address: AddressInbound;
    /**
     * 
     * @type {BankAccountInbound}
     * @memberof PersonLoanManagement
     */
    bankAccount: BankAccountInbound;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    taxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    birthDate: string;
    /**
     * ISO 3166-1 Alpha-3 code. Available values: [ AFG, ALB, DZA, ASM, AND, AGO, AIA, ATA, ATG, ARG, ARM, ABW, AUS, AUT, AZE, BHS, BHR, BGD, BRB, BLR, BEL, BLZ, BEN, BMU, BTN, BOL, BES, BIH, BWA, BVT, BRA, IOT, BRN, BGR, BFA, BDI, CPV, KHM, CMR, CAN, CYM, CAF, TCD, CHL, CHN, CXR, CCK, COL, COM, COD, COG, COK, CRI, HRV, CUB, CUW, CYP, CZE, CIV, DNK, DJI, DMA, DOM, ECU, EGY, SLV, GNQ, ERI, EST, SWZ, ETH, FLK, FRO, FJI, FIN, FRA, GUF, PYF, ATF, GAB, GMB, GEO, DEU, GHA, GIB, GRC, GRL, GRD, GLP, GUM, GTM, GGY, GIN, GNB, GUY, HTI, HMD, VAT, HND, HKG, HUN, ISL, IND, IDN, IRN, IRQ, IRL, IMN, ISR, ITA, JAM, JPN, JEY, JOR, KAZ, KEN, KIR, PRK, KOR, KWT, KGZ, LAO, LVA, LBN, LSO, LBR, LBY, LIE, LTU, LUX, MAC, MDG, MWI, MYS, MDV, MLI, MLT, MHL, MTQ, MRT, MUS, MYT, MEX, FSM, MDA, MCO, MNG, MNE, MSR, MAR, MOZ, MMR, NAM, NRU, NPL, NLD, NCL, NZL, NIC, NER, NGA, NIU, NFK, MNP, NOR, OMN, PAK, PLW, PSE, PAN, PNG, PRY, PER, PHL, PCN, POL, PRT, PRI, QAT, MKD, ROU, RUS, RWA, REU, BLM, SHN, KNA, LCA, MAF, SPM, VCT, WSM, SMR, STP, SAU, SEN, SRB, SYC, SLE, SGP, SXM, SVK, SVN, SLB, SOM, ZAF, SGS, SSD, ESP, LKA, SDN, SUR, SJM, SWE, CHE, SYR, TWN, TJK, TZA, THA, TLS, TGO, TKL, TON, TTO, TUN, TUR, TKM, TCA, TUV, UGA, UKR, ARE, GBR, UMI, USA, URY, UZB, VUT, VEN, VNM, VGB, VIR, WLF, ESH, YEM, ZMB, ZWE, ALA ]
     * @type {string}
     * @memberof PersonLoanManagement
     */
    citizenship?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    firstName: string;
    /**
     * 
     * @type {IdDocumentLoanManagement}
     * @memberof PersonLoanManagement
     */
    idDocument: IdDocumentLoanManagement;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagement
     */
    middleName?: string;
}
/**
 * 
 * @export
 * @interface PersonLoanManagementAllOf
 */
export interface PersonLoanManagementAllOf {
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagementAllOf
     */
    birthDate?: string;
    /**
     * ISO 3166-1 Alpha-3 code. Available values: [ AFG, ALB, DZA, ASM, AND, AGO, AIA, ATA, ATG, ARG, ARM, ABW, AUS, AUT, AZE, BHS, BHR, BGD, BRB, BLR, BEL, BLZ, BEN, BMU, BTN, BOL, BES, BIH, BWA, BVT, BRA, IOT, BRN, BGR, BFA, BDI, CPV, KHM, CMR, CAN, CYM, CAF, TCD, CHL, CHN, CXR, CCK, COL, COM, COD, COG, COK, CRI, HRV, CUB, CUW, CYP, CZE, CIV, DNK, DJI, DMA, DOM, ECU, EGY, SLV, GNQ, ERI, EST, SWZ, ETH, FLK, FRO, FJI, FIN, FRA, GUF, PYF, ATF, GAB, GMB, GEO, DEU, GHA, GIB, GRC, GRL, GRD, GLP, GUM, GTM, GGY, GIN, GNB, GUY, HTI, HMD, VAT, HND, HKG, HUN, ISL, IND, IDN, IRN, IRQ, IRL, IMN, ISR, ITA, JAM, JPN, JEY, JOR, KAZ, KEN, KIR, PRK, KOR, KWT, KGZ, LAO, LVA, LBN, LSO, LBR, LBY, LIE, LTU, LUX, MAC, MDG, MWI, MYS, MDV, MLI, MLT, MHL, MTQ, MRT, MUS, MYT, MEX, FSM, MDA, MCO, MNG, MNE, MSR, MAR, MOZ, MMR, NAM, NRU, NPL, NLD, NCL, NZL, NIC, NER, NGA, NIU, NFK, MNP, NOR, OMN, PAK, PLW, PSE, PAN, PNG, PRY, PER, PHL, PCN, POL, PRT, PRI, QAT, MKD, ROU, RUS, RWA, REU, BLM, SHN, KNA, LCA, MAF, SPM, VCT, WSM, SMR, STP, SAU, SEN, SRB, SYC, SLE, SGP, SXM, SVK, SVN, SLB, SOM, ZAF, SGS, SSD, ESP, LKA, SDN, SUR, SJM, SWE, CHE, SYR, TWN, TJK, TZA, THA, TLS, TGO, TKL, TON, TTO, TUN, TUR, TKM, TCA, TUV, UGA, UKR, ARE, GBR, UMI, USA, URY, UZB, VUT, VEN, VNM, VGB, VIR, WLF, ESH, YEM, ZMB, ZWE, ALA ]
     * @type {string}
     * @memberof PersonLoanManagementAllOf
     */
    citizenship?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagementAllOf
     */
    firstName?: string;
    /**
     * 
     * @type {IdDocumentLoanManagement}
     * @memberof PersonLoanManagementAllOf
     */
    idDocument?: IdDocumentLoanManagement;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagementAllOf
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanManagementAllOf
     */
    middleName?: string;
}
/**
 * Borrower details
 * @export
 * @interface PersonLoanOrigination
 */
export interface PersonLoanOrigination {
    /**
     * 
     * @type {AddressInbound}
     * @memberof PersonLoanOrigination
     */
    address: AddressInbound;
    /**
     * 
     * @type {BankAccountInbound}
     * @memberof PersonLoanOrigination
     */
    bankAccount: BankAccountInbound;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    birthDate: string;
    /**
     * ISO 3166-1 Alpha-3 code. Available values: [ AFG, ALB, DZA, ASM, AND, AGO, AIA, ATA, ATG, ARG, ARM, ABW, AUS, AUT, AZE, BHS, BHR, BGD, BRB, BLR, BEL, BLZ, BEN, BMU, BTN, BOL, BES, BIH, BWA, BVT, BRA, IOT, BRN, BGR, BFA, BDI, CPV, KHM, CMR, CAN, CYM, CAF, TCD, CHL, CHN, CXR, CCK, COL, COM, COD, COG, COK, CRI, HRV, CUB, CUW, CYP, CZE, CIV, DNK, DJI, DMA, DOM, ECU, EGY, SLV, GNQ, ERI, EST, SWZ, ETH, FLK, FRO, FJI, FIN, FRA, GUF, PYF, ATF, GAB, GMB, GEO, DEU, GHA, GIB, GRC, GRL, GRD, GLP, GUM, GTM, GGY, GIN, GNB, GUY, HTI, HMD, VAT, HND, HKG, HUN, ISL, IND, IDN, IRN, IRQ, IRL, IMN, ISR, ITA, JAM, JPN, JEY, JOR, KAZ, KEN, KIR, PRK, KOR, KWT, KGZ, LAO, LVA, LBN, LSO, LBR, LBY, LIE, LTU, LUX, MAC, MDG, MWI, MYS, MDV, MLI, MLT, MHL, MTQ, MRT, MUS, MYT, MEX, FSM, MDA, MCO, MNG, MNE, MSR, MAR, MOZ, MMR, NAM, NRU, NPL, NLD, NCL, NZL, NIC, NER, NGA, NIU, NFK, MNP, NOR, OMN, PAK, PLW, PSE, PAN, PNG, PRY, PER, PHL, PCN, POL, PRT, PRI, QAT, MKD, ROU, RUS, RWA, REU, BLM, SHN, KNA, LCA, MAF, SPM, VCT, WSM, SMR, STP, SAU, SEN, SRB, SYC, SLE, SGP, SXM, SVK, SVN, SLB, SOM, ZAF, SGS, SSD, ESP, LKA, SDN, SUR, SJM, SWE, CHE, SYR, TWN, TJK, TZA, THA, TLS, TGO, TKL, TON, TTO, TUN, TUR, TKM, TCA, TUV, UGA, UKR, ARE, GBR, UMI, USA, URY, UZB, VUT, VEN, VNM, VGB, VIR, WLF, ESH, YEM, ZMB, ZWE, ALA ]
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    citizenship?: string;
    /**
     * Available values: [ No formal education, Elementary school completed, High school graduate, Bachelor\'s degree, Master\'s degree, Doctorate degree ]
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    firstName: string;
    /**
     * 
     * @type {IdDocumentLoanOrigination}
     * @memberof PersonLoanOrigination
     */
    idDocument: IdDocumentLoanOrigination;
    /**
     * Available values: [ Agriculture; Plantations; Other rural sectors, Basic metal production, Chemical industries, Commerce, Construction, Education, Financial services, Food; Drink; Tobacco, Forestry; Wood; Pulp and paper, Health services, Hotels; Tourism; Catering, Information technology, Mining, Mechanical and electrical engineering, Media; Culture; Graphical, Postal and telecommunications services, Public service, Real estate; Rental; Leasing, Repair and maintenance, Shipping; Ports; Fisheries; Inland waterways, Textiles; Clothing; Leather; Footwear, Transport, Utilities ]
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    lastName: string;
    /**
     * Available values: [ < 6 months, 6 - 12 months, 1 - 2 years, 2 - 4 years, > 4 years ]
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    lengthOfEmployment?: string;
    /**
     * Available values: [ Married, Widowed, Separated, Divorced, Single ]
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    maritalStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    middleName?: string;
    /**
     * 
     * @type {number}
     * @memberof PersonLoanOrigination
     */
    monthlyIncome?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    phone: string;
    /**
     * Borrower\'s current job position. Available values: [ Trainee, Regular employee, Middle level manager, Senior level manager, Owner, Retired, Non-employed ]
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    position?: string;
    /**
     * Flag indicating whether the borrower has a property
     * @type {boolean}
     * @memberof PersonLoanOrigination
     */
    property?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PersonLoanOrigination
     */
    taxNumber?: string;
    /**
     * Flag indicating whether the borrower has a vehicle
     * @type {boolean}
     * @memberof PersonLoanOrigination
     */
    vehicle?: boolean;
}
/**
 * 
 * @export
 * @interface ProcessDefinitionRepresentation
 */
export interface ProcessDefinitionRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    deploymentId: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    diagram: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProcessDefinitionRepresentation
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof ProcessDefinitionRepresentation
     */
    historyTimeToLive?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    resource?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    startFormKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessDefinitionRepresentation
     */
    startableInTasklist?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessDefinitionRepresentation
     */
    suspended: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    tenantId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessDefinitionRepresentation
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    versionTag?: string;
}
/**
 * 
 * @export
 * @interface ProfitabilityRatios
 */
export interface ProfitabilityRatios {
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    ebitdaMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    grossProfitMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    netDebtToEbitda: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    netProfitMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    netWorthToTotalAssets: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    operatingProfitMargin: number;
}
/**
 * 
 * @export
 * @interface ProfitabilityRatiosBorrowerAllowed
 */
export interface ProfitabilityRatiosBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBorrowerAllowed
     */
    ebitdaMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBorrowerAllowed
     */
    grossProfitMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBorrowerAllowed
     */
    netDebtToEbitda: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBorrowerAllowed
     */
    netProfitMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBorrowerAllowed
     */
    netWorthToTotalAssets: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBorrowerAllowed
     */
    operatingProfitMargin: number;
}
/**
 * 
 * @export
 * @interface ProfitabilityRatiosBrief
 */
export interface ProfitabilityRatiosBrief {
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    ebitdaMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    grossProfitMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    netDebtToEbitda: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    netProfitMargin: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    netWorthToTotalAssets: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    operatingProfitMargin: number;
}
/**
 * 
 * @export
 * @interface Representative
 */
export interface Representative {
    /**
     * 
     * @type {number}
     * @memberof Representative
     */
    id?: number;
    /**
     * 
     * @type {Person}
     * @memberof Representative
     */
    person: Person;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    position: string;
}
/**
 * A person representing the legal entity
 * @export
 * @interface RepresentativeLoanOrigination
 */
export interface RepresentativeLoanOrigination {
    /**
     * 
     * @type {string}
     * @memberof RepresentativeLoanOrigination
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof RepresentativeLoanOrigination
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RepresentativeLoanOrigination
     */
    firstName: string;
    /**
     * 
     * @type {IdDocumentLoanOrigination}
     * @memberof RepresentativeLoanOrigination
     */
    idDocument: IdDocumentLoanOrigination;
    /**
     * 
     * @type {string}
     * @memberof RepresentativeLoanOrigination
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RepresentativeLoanOrigination
     */
    middleName?: string;
    /**
     * Representative\'s current job position
     * @type {string}
     * @memberof RepresentativeLoanOrigination
     */
    position: string;
}
/**
 * The loan application is used to make a decision on originating a loan
 * @export
 * @interface RequestLegalEntityLoanOrigination
 */
export interface RequestLegalEntityLoanOrigination {
    /**
     * The amount for which the loan is requested
     * @type {number}
     * @memberof RequestLegalEntityLoanOrigination
     */
    amountRequested: number;
    /**
     * 
     * @type {LegalEntityLoanOrigination}
     * @memberof RequestLegalEntityLoanOrigination
     */
    borrower: LegalEntityLoanOrigination;
    /**
     * Code of the campaign (product group) that exists in the system for which the loan application is applied
     * @type {string}
     * @memberof RequestLegalEntityLoanOrigination
     */
    campaignCode: string;
    /**
     * Required for campaigns with collateral
     * @type {number}
     * @memberof RequestLegalEntityLoanOrigination
     */
    collateralValue?: number;
    /**
     * Date when payment(s) has to be performed. Value is allowed only for \'Annuity instalment\' and \'Linear principal repayment instalment\' calculation types
     * @type {string}
     * @memberof RequestLegalEntityLoanOrigination
     */
    firstPaymentDate?: string;
    /**
     * The desired repayment period for which the loan is requested
     * @type {string}
     * @memberof RequestLegalEntityLoanOrigination
     */
    paymentFrequency?: RequestLegalEntityLoanOriginationPaymentFrequencyEnum;
    /**
     * Date and time when the application has been applied
     * @type {string}
     * @memberof RequestLegalEntityLoanOrigination
     */
    requestedAt: string;
    /**
     * The term for which the loan is requested
     * @type {number}
     * @memberof RequestLegalEntityLoanOrigination
     */
    termRequested: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RequestLegalEntityLoanOriginationPaymentFrequencyEnum {
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY'
}

/**
 * The loan application is used to create a loan
 * @export
 * @interface RequestLoanManagement
 */
export interface RequestLoanManagement {
    /**
     * Loan amount to disburse
     * @type {number}
     * @memberof RequestLoanManagement
     */
    amountApproved: number;
    /**
     * 
     * @type {LegalEntityLoanManagement | PersonLoanManagement}
     * @memberof RequestLoanManagement
     */
    borrower: LegalEntityLoanManagement | PersonLoanManagement;
    /**
     * Date when payment(s) has to be performed. Value is allowed only for \'Annuity instalment\' and \'Linear principal repayment instalment\' calculation types
     * @type {string}
     * @memberof RequestLoanManagement
     */
    firstPaymentDate?: string;
    /**
     * Interest rate measured as a percentage
     * @type {number}
     * @memberof RequestLoanManagement
     */
    interestRateApprovedPct: number;
    /**
     * 
     * @type {LoanProductLoanManagement}
     * @memberof RequestLoanManagement
     */
    loanProduct: LoanProductLoanManagement;
    /**
     * Unique loan application number
     * @type {string}
     * @memberof RequestLoanManagement
     */
    number: string;
    /**
     * Date and time when the application has been applied
     * @type {string}
     * @memberof RequestLoanManagement
     */
    requestedAt: string;
    /**
     * Approved loan term
     * @type {number}
     * @memberof RequestLoanManagement
     */
    termApproved: number;
}
/**
 * The loan application is used to make a decision on originating a loan
 * @export
 * @interface RequestPersonLoanOrigination
 */
export interface RequestPersonLoanOrigination {
    /**
     * The amount for which the loan is requested
     * @type {number}
     * @memberof RequestPersonLoanOrigination
     */
    amountRequested: number;
    /**
     * 
     * @type {PersonLoanOrigination}
     * @memberof RequestPersonLoanOrigination
     */
    borrower: PersonLoanOrigination;
    /**
     * Code of the campaign (product group) that exists in the system for which the loan application is applied
     * @type {string}
     * @memberof RequestPersonLoanOrigination
     */
    campaignCode: string;
    /**
     * Required for campaigns with collateral
     * @type {number}
     * @memberof RequestPersonLoanOrigination
     */
    collateralValue?: number;
    /**
     * Date when payment(s) has to be performed. Value is allowed only for \'Annuity instalment\' and \'Linear principal repayment instalment\' calculation types
     * @type {string}
     * @memberof RequestPersonLoanOrigination
     */
    firstPaymentDate?: string;
    /**
     * The desired repayment period for which the loan is requested
     * @type {string}
     * @memberof RequestPersonLoanOrigination
     */
    paymentFrequency?: RequestPersonLoanOriginationPaymentFrequencyEnum;
    /**
     * Date and time when the application has been applied
     * @type {string}
     * @memberof RequestPersonLoanOrigination
     */
    requestedAt: string;
    /**
     * The term for which the loan is requested
     * @type {number}
     * @memberof RequestPersonLoanOrigination
     */
    termRequested: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RequestPersonLoanOriginationPaymentFrequencyEnum {
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY'
}

/**
 * 
 * @export
 * @interface RevisionIntegerBorrower
 */
export interface RevisionIntegerBorrower {
    /**
     * 
     * @type {LegalEntity | Person}
     * @memberof RevisionIntegerBorrower
     */
    entity?: LegalEntity | Person;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerBorrower
     */
    metadata?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerBorrower
     */
    requiredRevisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerBorrower
     */
    requiredRevisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerBorrower
     */
    revisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerBorrower
     */
    revisionNumber?: number;
}
/**
 * 
 * @export
 * @interface RevisionIntegerLoanProduct
 */
export interface RevisionIntegerLoanProduct {
    /**
     * 
     * @type {LoanProduct}
     * @memberof RevisionIntegerLoanProduct
     */
    entity?: LoanProduct;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerLoanProduct
     */
    metadata?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanProduct
     */
    requiredRevisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanProduct
     */
    requiredRevisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanProduct
     */
    revisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanProduct
     */
    revisionNumber?: number;
}
/**
 * 
 * @export
 * @interface RevisionMetadataInteger
 */
export interface RevisionMetadataInteger {
    /**
     * 
     * @type {object}
     * @memberof RevisionMetadataInteger
     */
    delegate?: object;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    requiredRevisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionMetadataInteger
     */
    requiredRevisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    revisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionMetadataInteger
     */
    revisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    revisionType?: RevisionMetadataIntegerRevisionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RevisionMetadataIntegerRevisionTypeEnum {
    UNKNOWN = 'UNKNOWN',
    INSERT = 'INSERT',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

/**
 * 
 * @export
 * @interface Scoring
 */
export interface Scoring {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Scoring
     */
    response?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Scoring
     */
    resultScore?: string;
}
/**
 * 
 * @export
 * @interface ScoringBorrowerAllowed
 */
export interface ScoringBorrowerAllowed {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ScoringBorrowerAllowed
     */
    response?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ScoringBorrowerAllowed
     */
    resultScore?: string;
}
/**
 * 
 * @export
 * @interface ScoringBrief
 */
export interface ScoringBrief {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ScoringBrief
     */
    response?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ScoringBrief
     */
    resultScore?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface SortBorrowerAllowed
 */
export interface SortBorrowerAllowed {
    /**
     * 
     * @type {boolean}
     * @memberof SortBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBorrowerAllowed
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBorrowerAllowed
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface SortBrief
 */
export interface SortBrief {
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface TaskRepresentation
 */
export interface TaskRepresentation {
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    assignee?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    caseDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    caseExecutionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    caseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    createTime: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    delegationState?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    deploymentId: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    executionId: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskRepresentation
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    followUpDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    formKey?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    parentTaskId?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRepresentation
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    processDefinitionId: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    processInstanceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskRepresentation
     */
    suspended: boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    taskDefinitionKey: string;
    /**
     * 
     * @type {object}
     * @memberof TaskRepresentation
     */
    taskInfo?: object;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    tenantId?: string;
}
/**
 * 
 * @export
 * @interface TemplateDescription
 */
export interface TemplateDescription {
    /**
     * 
     * @type {string}
     * @memberof TemplateDescription
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDescription
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface TermOption
 */
export interface TermOption {
    /**
     * 
     * @type {number}
     * @memberof TermOption
     */
    length?: number;
}
/**
 * 
 * @export
 * @interface TermOptionBorrowerAllowed
 */
export interface TermOptionBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof TermOptionBorrowerAllowed
     */
    length?: number;
}
/**
 * 
 * @export
 * @interface TermOptionBrief
 */
export interface TermOptionBrief {
    /**
     * 
     * @type {number}
     * @memberof TermOptionBrief
     */
    length?: number;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    accountNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    direction: TransactionDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    externalPaymentService?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    initiator?: string;
    /**
     * 
     * @type {Loan}
     * @memberof Transaction
     */
    loan: Loan;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    method?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    processed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    type: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionDirectionEnum {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface TransactionBorrowerAllowed
 */
export interface TransactionBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    accountNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBorrowerAllowed
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    initiator?: string;
    /**
     * 
     * @type {LoanBorrowerAllowed}
     * @memberof TransactionBorrowerAllowed
     */
    loan: LoanBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBorrowerAllowed
     */
    type: string;
}
/**
 * 
 * @export
 * @interface TransactionBrief
 */
export interface TransactionBrief {
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    accountNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBrief
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    direction: TransactionBriefDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    externalPaymentService?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    initiator?: string;
    /**
     * 
     * @type {LoanBrief}
     * @memberof TransactionBrief
     */
    loan: LoanBrief;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    method?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionBrief
     */
    processed: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    type: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionBriefDirectionEnum {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface UiConfigurations
 */
export interface UiConfigurations {
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    appName?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    authClientId?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    authRealm?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    authServerUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    borrowerType?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    defaultLocale?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    defaultProcessKey?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UiConfigurations
     */
    env?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof UiConfigurations
     */
    supportedLocales?: Array<string>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface UtmParam
 */
export interface UtmParam {
    /**
     * 
     * @type {string}
     * @memberof UtmParam
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UtmParam
     */
    value?: string;
}

/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Actuator web endpoint \'env\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        env: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/env`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'env-toMatch\'
         * @param {string} toMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envToMatch: async (toMatch: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'toMatch' is not null or undefined
            if (toMatch === null || toMatch === undefined) {
                throw new RequiredError('toMatch','Required parameter toMatch was null or undefined when calling envToMatch.');
            }
            const localVarPath = `/actuator/env/{toMatch}`
                .replace(`{${"toMatch"}}`, encodeURIComponent(String(toMatch)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPath: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metrics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/metrics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics-requiredMetricName\'
         * @param {string} requiredMetricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRequiredMetricName: async (requiredMetricName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requiredMetricName' is not null or undefined
            if (requiredMetricName === null || requiredMetricName === undefined) {
                throw new RequiredError('requiredMetricName','Required parameter requiredMetricName was null or undefined when calling metricsRequiredMetricName.');
            }
            const localVarPath = `/actuator/metrics/{requiredMetricName}`
                .replace(`{${"requiredMetricName"}}`, encodeURIComponent(String(requiredMetricName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/prometheus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Actuator web endpoint \'env\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async env(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).env(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'env-toMatch\'
         * @param {string} toMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async envToMatch(toMatch: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).envToMatch(toMatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).health(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthPath(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).healthPath(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async info(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).info(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async links(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Link; }; }>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).links(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metrics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).metrics(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics-requiredMetricName\'
         * @param {string} requiredMetricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsRequiredMetricName(requiredMetricName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).metricsRequiredMetricName(requiredMetricName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prometheus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).prometheus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Actuator web endpoint \'env\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        env(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).env(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'env-toMatch\'
         * @param {string} toMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envToMatch(toMatch: string, options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).envToMatch(toMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).health(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPath(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).healthPath(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).info(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links(options?: any): AxiosPromise<{ [key: string]: { [key: string]: Link; }; }> {
            return ActuatorApiFp(configuration).links(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metrics(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).metrics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics-requiredMetricName\'
         * @param {string} requiredMetricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRequiredMetricName(requiredMetricName: string, options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).metricsRequiredMetricName(requiredMetricName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheus(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).prometheus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * 
     * @summary Actuator web endpoint \'env\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public env(options?: any) {
        return ActuatorApiFp(this.configuration).env(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'env-toMatch\'
     * @param {string} toMatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public envToMatch(toMatch: string, options?: any) {
        return ActuatorApiFp(this.configuration).envToMatch(toMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'health\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public health(options?: any) {
        return ActuatorApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'health-path\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public healthPath(options?: any) {
        return ActuatorApiFp(this.configuration).healthPath(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'info\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public info(options?: any) {
        return ActuatorApiFp(this.configuration).info(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator root web endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public links(options?: any) {
        return ActuatorApiFp(this.configuration).links(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'metrics\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public metrics(options?: any) {
        return ActuatorApiFp(this.configuration).metrics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'metrics-requiredMetricName\'
     * @param {string} requiredMetricName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public metricsRequiredMetricName(requiredMetricName: string, options?: any) {
        return ActuatorApiFp(this.configuration).metricsRequiredMetricName(requiredMetricName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'prometheus\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public prometheus(options?: any) {
        return ActuatorApiFp(this.configuration).prometheus(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AgentApi - axios parameter creator
 * @export
 */
export const AgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create agent
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent: async (agent: Agent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agent' is not null or undefined
            if (agent === null || agent === undefined) {
                throw new RequiredError('agent','Required parameter agent was null or undefined when calling createAgent.');
            }
            const localVarPath = `/api/agents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof agent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(agent !== undefined ? agent : {}) : (agent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of agents
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by agent\&#39;s legalName, phone, email
         * @param {string} [filterTaxNumber] Search by agent\&#39;s taxNumber
         * @param {string} [filterLegalName] Search by agent\&#39;s legalName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAgents: async (page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterTaxNumber?: string, filterLegalName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/agents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }

            if (filterTaxNumber !== undefined) {
                localVarQueryParameter['filter.taxNumber'] = filterTaxNumber;
            }

            if (filterLegalName !== undefined) {
                localVarQueryParameter['filter.legalName'] = filterLegalName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of agent\'s users
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsersByAgentId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllUsersByAgentId.');
            }
            const localVarPath = `/api/agents/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single agent
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAgent.');
            }
            const localVarPath = `/api/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent
         * @param {number} id 
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent: async (id: number, agent: Agent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAgent.');
            }
            // verify required parameter 'agent' is not null or undefined
            if (agent === null || agent === undefined) {
                throw new RequiredError('agent','Required parameter agent was null or undefined when calling updateAgent.');
            }
            const localVarPath = `/api/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof agent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(agent !== undefined ? agent : {}) : (agent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentApi - functional programming interface
 * @export
 */
export const AgentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create agent
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgent(agent: Agent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await AgentApiAxiosParamCreator(configuration).createAgent(agent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of agents
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by agent\&#39;s legalName, phone, email
         * @param {string} [filterTaxNumber] Search by agent\&#39;s taxNumber
         * @param {string} [filterLegalName] Search by agent\&#39;s legalName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAgents(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterTaxNumber?: string, filterLegalName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAgentBrief>> {
            const localVarAxiosArgs = await AgentApiAxiosParamCreator(configuration).findAllAgents(page, size, sort, filterSearch, filterTaxNumber, filterLegalName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of agent\'s users
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllUsersByAgentId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUser>> {
            const localVarAxiosArgs = await AgentApiAxiosParamCreator(configuration).findAllUsersByAgentId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single agent
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgent(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await AgentApiAxiosParamCreator(configuration).getAgent(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update agent
         * @param {number} id 
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent(id: number, agent: Agent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await AgentApiAxiosParamCreator(configuration).updateAgent(id, agent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AgentApi - factory interface
 * @export
 */
export const AgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create agent
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent(agent: Agent, options?: any): AxiosPromise<Agent> {
            return AgentApiFp(configuration).createAgent(agent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of agents
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by agent\&#39;s legalName, phone, email
         * @param {string} [filterTaxNumber] Search by agent\&#39;s taxNumber
         * @param {string} [filterLegalName] Search by agent\&#39;s legalName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAgents(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterTaxNumber?: string, filterLegalName?: string, options?: any): AxiosPromise<PageAgentBrief> {
            return AgentApiFp(configuration).findAllAgents(page, size, sort, filterSearch, filterTaxNumber, filterLegalName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of agent\'s users
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsersByAgentId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageUser> {
            return AgentApiFp(configuration).findAllUsersByAgentId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single agent
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(id: number, options?: any): AxiosPromise<Agent> {
            return AgentApiFp(configuration).getAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent
         * @param {number} id 
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent(id: number, agent: Agent, options?: any): AxiosPromise<Agent> {
            return AgentApiFp(configuration).updateAgent(id, agent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentApi - object-oriented interface
 * @export
 * @class AgentApi
 * @extends {BaseAPI}
 */
export class AgentApi extends BaseAPI {
    /**
     * 
     * @summary Create agent
     * @param {Agent} agent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public createAgent(agent: Agent, options?: any) {
        return AgentApiFp(this.configuration).createAgent(agent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of agents
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [filterSearch] Search by agent\&#39;s legalName, phone, email
     * @param {string} [filterTaxNumber] Search by agent\&#39;s taxNumber
     * @param {string} [filterLegalName] Search by agent\&#39;s legalName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public findAllAgents(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterTaxNumber?: string, filterLegalName?: string, options?: any) {
        return AgentApiFp(this.configuration).findAllAgents(page, size, sort, filterSearch, filterTaxNumber, filterLegalName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of agent\'s users
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public findAllUsersByAgentId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AgentApiFp(this.configuration).findAllUsersByAgentId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single agent
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public getAgent(id: number, options?: any) {
        return AgentApiFp(this.configuration).getAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent
     * @param {number} id 
     * @param {Agent} agent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public updateAgent(id: number, agent: Agent, options?: any) {
        return AgentApiFp(this.configuration).updateAgent(id, agent, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AgentLoanApi - axios parameter creator
 * @export
 */
export const AgentLoanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loans for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAgentLoans: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/agent/loans`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterIssueDateFrom !== undefined) {
                localVarQueryParameter['filter.issueDateFrom'] = filterIssueDateFrom;
            }

            if (filterIssueDateTo !== undefined) {
                localVarQueryParameter['filter.issueDateTo'] = filterIssueDateTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return agent\'s single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLoan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAgentLoan.');
            }
            const localVarPath = `/api/agent/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentLoanApi - functional programming interface
 * @export
 */
export const AgentLoanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loans for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAgentLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await AgentLoanApiAxiosParamCreator(configuration).findAllAgentLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return agent\'s single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentLoan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Loan>> {
            const localVarAxiosArgs = await AgentLoanApiAxiosParamCreator(configuration).getAgentLoan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AgentLoanApi - factory interface
 * @export
 */
export const AgentLoanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loans for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAgentLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageLoanBrief> {
            return AgentLoanApiFp(configuration).findAllAgentLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return agent\'s single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLoan(id: number, options?: any): AxiosPromise<Loan> {
            return AgentLoanApiFp(configuration).getAgentLoan(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentLoanApi - object-oriented interface
 * @export
 * @class AgentLoanApi
 * @extends {BaseAPI}
 */
export class AgentLoanApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loans for agent
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by loan statuses
     * @param {string} [filterIssueDateFrom] Search by loan issue date from
     * @param {string} [filterIssueDateTo] Search by loan issue date to
     * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentLoanApi
     */
    public findAllAgentLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any) {
        return AgentLoanApiFp(this.configuration).findAllAgentLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return agent\'s single loan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentLoanApi
     */
    public getAgentLoan(id: number, options?: any) {
        return AgentLoanApiFp(this.configuration).getAgentLoan(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AgentLoanApplicationApi - axios parameter creator
 * @export
 */
export const AgentLoanApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan applications for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAgentApplications: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/agent/loan-applications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterRequestedAtFrom !== undefined) {
                localVarQueryParameter['filter.requestedAtFrom'] = filterRequestedAtFrom;
            }

            if (filterRequestedAtTo !== undefined) {
                localVarQueryParameter['filter.requestedAtTo'] = filterRequestedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return agent\'s single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAgentApplication.');
            }
            const localVarPath = `/api/agent/loan-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentLoanApplicationApi - functional programming interface
 * @export
 */
export const AgentLoanApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan applications for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAgentApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBorrowerBrief>> {
            const localVarAxiosArgs = await AgentLoanApplicationApiAxiosParamCreator(configuration).findAllAgentApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return agent\'s single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanApplication>> {
            const localVarAxiosArgs = await AgentLoanApplicationApiAxiosParamCreator(configuration).getAgentApplication(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AgentLoanApplicationApi - factory interface
 * @export
 */
export const AgentLoanApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loan applications for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAgentApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageLoanApplicationBorrowerBrief> {
            return AgentLoanApplicationApiFp(configuration).findAllAgentApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return agent\'s single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentApplication(id: number, options?: any): AxiosPromise<LoanApplication> {
            return AgentLoanApplicationApiFp(configuration).getAgentApplication(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentLoanApplicationApi - object-oriented interface
 * @export
 * @class AgentLoanApplicationApi
 * @extends {BaseAPI}
 */
export class AgentLoanApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan applications for agent
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by loan application statuses
     * @param {string} [filterRequestedAtFrom] Search by loan application requested at from
     * @param {string} [filterRequestedAtTo] Search by loan application requested at to
     * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentLoanApplicationApi
     */
    public findAllAgentApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any) {
        return AgentLoanApplicationApiFp(this.configuration).findAllAgentApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return agent\'s single loan application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentLoanApplicationApi
     */
    public getAgentApplication(id: number, options?: any) {
        return AgentLoanApplicationApiFp(this.configuration).getAgentApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AgentPortalApi - axios parameter creator
 * @export
 */
export const AgentPortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return agent profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/agent`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent1: async (agent: Agent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agent' is not null or undefined
            if (agent === null || agent === undefined) {
                throw new RequiredError('agent','Required parameter agent was null or undefined when calling updateAgent1.');
            }
            const localVarPath = `/api/agent`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof agent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(agent !== undefined ? agent : {}) : (agent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentPortalApi - functional programming interface
 * @export
 */
export const AgentPortalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return agent profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await AgentPortalApiAxiosParamCreator(configuration).getAgentProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update agent
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent1(agent: Agent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await AgentPortalApiAxiosParamCreator(configuration).updateAgent1(agent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AgentPortalApi - factory interface
 * @export
 */
export const AgentPortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return agent profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentProfile(options?: any): AxiosPromise<Agent> {
            return AgentPortalApiFp(configuration).getAgentProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent
         * @param {Agent} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent1(agent: Agent, options?: any): AxiosPromise<Agent> {
            return AgentPortalApiFp(configuration).updateAgent1(agent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentPortalApi - object-oriented interface
 * @export
 * @class AgentPortalApi
 * @extends {BaseAPI}
 */
export class AgentPortalApi extends BaseAPI {
    /**
     * 
     * @summary Return agent profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPortalApi
     */
    public getAgentProfile(options?: any) {
        return AgentPortalApiFp(this.configuration).getAgentProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent
     * @param {Agent} agent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPortalApi
     */
    public updateAgent1(agent: Agent, options?: any) {
        return AgentPortalApiFp(this.configuration).updateAgent1(agent, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BPMSIncidentsManagementApi - axios parameter creator
 * @export
 */
export const BPMSIncidentsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRetryIncidents: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incident/bulk-retry`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BPMSIncidentsManagementApi - functional programming interface
 * @export
 */
export const BPMSIncidentsManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkRetryIncidents(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkRetryIncidentsResponse>> {
            const localVarAxiosArgs = await BPMSIncidentsManagementApiAxiosParamCreator(configuration).bulkRetryIncidents(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BPMSIncidentsManagementApi - factory interface
 * @export
 */
export const BPMSIncidentsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRetryIncidents(options?: any): AxiosPromise<BulkRetryIncidentsResponse> {
            return BPMSIncidentsManagementApiFp(configuration).bulkRetryIncidents(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BPMSIncidentsManagementApi - object-oriented interface
 * @export
 * @class BPMSIncidentsManagementApi
 * @extends {BaseAPI}
 */
export class BPMSIncidentsManagementApi extends BaseAPI {
    /**
     * Asynchronously increase retry count for all insidents in the system
     * @summary Bulk retry incidents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSIncidentsManagementApi
     */
    public bulkRetryIncidents(options?: any) {
        return BPMSIncidentsManagementApiFp(this.configuration).bulkRetryIncidents(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BPMSResourcesManagementApi - axios parameter creator
 * @export
 */
export const BPMSResourcesManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<any>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentName' is not null or undefined
            if (deploymentName === null || deploymentName === undefined) {
                throw new RequiredError('deploymentName','Required parameter deploymentName was null or undefined when calling create.');
            }
            // verify required parameter 'files' is not null or undefined
            if (files === null || files === undefined) {
                throw new RequiredError('files','Required parameter files was null or undefined when calling create.');
            }
            const localVarPath = `/api/deployment/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (deploymentName !== undefined) {
                localVarQueryParameter['deployment-name'] = deploymentName;
            }

            if (files) {
                localVarQueryParameter['files'] = files;
            }

            if (migrate !== undefined) {
                localVarQueryParameter['migrate'] = migrate;
            }

            if (retryIncidents !== undefined) {
                localVarQueryParameter['retry-incidents'] = retryIncidents;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm: async (formKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'formKey' is not null or undefined
            if (formKey === null || formKey === undefined) {
                throw new RequiredError('formKey','Required parameter formKey was null or undefined when calling getForm.');
            }
            const localVarPath = `/api/deployment/form`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (formKey !== undefined) {
                localVarQueryParameter['form-key'] = formKey;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestResource: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/resource/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BPMSResourcesManagementApi - functional programming interface
 * @export
 */
export const BPMSResourcesManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<any>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentRepresentation>> {
            const localVarAxiosArgs = await BPMSResourcesManagementApiAxiosParamCreator(configuration).create(deploymentName, files, migrate, retryIncidents, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForm(formKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await BPMSResourcesManagementApiAxiosParamCreator(configuration).getForm(formKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestResource(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await BPMSResourcesManagementApiAxiosParamCreator(configuration).getLatestResource(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BPMSResourcesManagementApi - factory interface
 * @export
 */
export const BPMSResourcesManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<any>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options?: any): AxiosPromise<DeploymentRepresentation> {
            return BPMSResourcesManagementApiFp(configuration).create(deploymentName, files, migrate, retryIncidents, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm(formKey: string, options?: any): AxiosPromise<object> {
            return BPMSResourcesManagementApiFp(configuration).getForm(formKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestResource(options?: any): AxiosPromise<any> {
            return BPMSResourcesManagementApiFp(configuration).getLatestResource(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BPMSResourcesManagementApi - object-oriented interface
 * @export
 * @class BPMSResourcesManagementApi
 * @extends {BaseAPI}
 */
export class BPMSResourcesManagementApi extends BaseAPI {
    /**
     * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
     * @summary Create a deployment with specified resources
     * @param {string} deploymentName Name for the deployment
     * @param {Array<any>} files Resources which the deployment will consist of
     * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
     * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public create(deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options?: any) {
        return BPMSResourcesManagementApiFp(this.configuration).create(deploymentName, files, migrate, retryIncidents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a form by key from \'/public\' folder in deployment storage
     * @summary Retrieves a deployed form by key
     * @param {string} formKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public getForm(formKey: string, options?: any) {
        return BPMSResourcesManagementApiFp(this.configuration).getForm(formKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves latest version of the resource by name from \'/public\' folder from deployment
     * @summary Retrieves a public resource by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public getLatestResource(options?: any) {
        return BPMSResourcesManagementApiFp(this.configuration).getLatestResource(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BorrowerApi - axios parameter creator
 * @export
 */
export const BorrowerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of borrowers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterBlacklisted] Search by borrower\&#39;s blacklisted flag
         * @param {string} [filterSearch] Search by borrower\&#39;s full name, phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBorrowers: async (page?: number, size?: number, sort?: Array<string>, filterBlacklisted?: boolean, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrowers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterBlacklisted !== undefined) {
                localVarQueryParameter['filter.blacklisted'] = filterBlacklisted;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower\'s black list record if exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlacklistEntry: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findBlacklistEntry.');
            }
            const localVarPath = `/api/borrowers/{id}/blacklist-entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single borrower
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrower: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrower.');
            }
            const localVarPath = `/api/borrowers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrowerHistory.');
            }
            const localVarPath = `/api/borrowers/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update borrower
         * @param {number} id 
         * @param {LegalEntity | Person} legalEntityPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBorrower: async (id: number, legalEntityPerson: LegalEntity | Person, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBorrower.');
            }
            // verify required parameter 'legalEntityPerson' is not null or undefined
            if (legalEntityPerson === null || legalEntityPerson === undefined) {
                throw new RequiredError('legalEntityPerson','Required parameter legalEntityPerson was null or undefined when calling updateBorrower.');
            }
            const localVarPath = `/api/borrowers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof legalEntityPerson !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(legalEntityPerson !== undefined ? legalEntityPerson : {}) : (legalEntityPerson || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BorrowerApi - functional programming interface
 * @export
 */
export const BorrowerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of borrowers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterBlacklisted] Search by borrower\&#39;s blacklisted flag
         * @param {string} [filterSearch] Search by borrower\&#39;s full name, phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllBorrowers(page?: number, size?: number, sort?: Array<string>, filterBlacklisted?: boolean, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBorrowerBrief>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).findAllBorrowers(page, size, sort, filterBlacklisted, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower\'s black list record if exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBlacklistEntry(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlacklistEntryBorrower>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).findBlacklistEntry(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single borrower
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrower(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntity | Person>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).getBorrower(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerBorrower>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).getBorrowerHistory(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update borrower
         * @param {number} id 
         * @param {LegalEntity | Person} legalEntityPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBorrower(id: number, legalEntityPerson: LegalEntity | Person, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntity | Person>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).updateBorrower(id, legalEntityPerson, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BorrowerApi - factory interface
 * @export
 */
export const BorrowerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of borrowers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterBlacklisted] Search by borrower\&#39;s blacklisted flag
         * @param {string} [filterSearch] Search by borrower\&#39;s full name, phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBorrowers(page?: number, size?: number, sort?: Array<string>, filterBlacklisted?: boolean, filterSearch?: string, options?: any): AxiosPromise<PageBorrowerBrief> {
            return BorrowerApiFp(configuration).findAllBorrowers(page, size, sort, filterBlacklisted, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower\'s black list record if exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlacklistEntry(id: string, options?: any): AxiosPromise<BlacklistEntryBorrower> {
            return BorrowerApiFp(configuration).findBlacklistEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single borrower
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrower(id: number, options?: any): AxiosPromise<LegalEntity | Person> {
            return BorrowerApiFp(configuration).getBorrower(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageRevisionIntegerBorrower> {
            return BorrowerApiFp(configuration).getBorrowerHistory(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update borrower
         * @param {number} id 
         * @param {LegalEntity | Person} legalEntityPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBorrower(id: number, legalEntityPerson: LegalEntity | Person, options?: any): AxiosPromise<LegalEntity | Person> {
            return BorrowerApiFp(configuration).updateBorrower(id, legalEntityPerson, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BorrowerApi - object-oriented interface
 * @export
 * @class BorrowerApi
 * @extends {BaseAPI}
 */
export class BorrowerApi extends BaseAPI {
    /**
     * 
     * @summary Return list of borrowers
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {boolean} [filterBlacklisted] Search by borrower\&#39;s blacklisted flag
     * @param {string} [filterSearch] Search by borrower\&#39;s full name, phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public findAllBorrowers(page?: number, size?: number, sort?: Array<string>, filterBlacklisted?: boolean, filterSearch?: string, options?: any) {
        return BorrowerApiFp(this.configuration).findAllBorrowers(page, size, sort, filterBlacklisted, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower\'s black list record if exists
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public findBlacklistEntry(id: string, options?: any) {
        return BorrowerApiFp(this.configuration).findBlacklistEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single borrower
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public getBorrower(id: number, options?: any) {
        return BorrowerApiFp(this.configuration).getBorrower(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower\'s history
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public getBorrowerHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerApiFp(this.configuration).getBorrowerHistory(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update borrower
     * @param {number} id 
     * @param {LegalEntity | Person} legalEntityPerson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public updateBorrower(id: number, legalEntityPerson: LegalEntity | Person, options?: any) {
        return BorrowerApiFp(this.configuration).updateBorrower(id, legalEntityPerson, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BorrowerPortalApi - axios parameter creator
 * @export
 */
export const BorrowerPortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions for borrower
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactionsByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllTransactionsByLoanId.');
            }
            const localVarPath = `/api/borrower/loan/{id}/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrowerApplication.');
            }
            const localVarPath = `/api/borrower/loanApplications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan applications for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplications: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrower/loanApplications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrowerLoan.');
            }
            const localVarPath = `/api/borrower/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loans for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoans: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrower/loans`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrower/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save borrower\'s UTM parameters
         * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUtmParams: async (utmParam: Array<UtmParam>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'utmParam' is not null or undefined
            if (utmParam === null || utmParam === undefined) {
                throw new RequiredError('utmParam','Required parameter utmParam was null or undefined when calling saveUtmParams.');
            }
            const localVarPath = `/api/borrower/utmParams`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof utmParam !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(utmParam !== undefined ? utmParam : {}) : (utmParam || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BorrowerPortalApi - functional programming interface
 * @export
 */
export const BorrowerPortalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions for borrower
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTransactionsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransactionBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).findAllTransactionsByLoanId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanApplicationBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerApplication(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan applications for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerApplications(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBorrowerBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerApplications(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerLoan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerLoan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loans for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerLoans(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerLoans(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntity | Person>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save borrower\'s UTM parameters
         * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUtmParams(utmParam: Array<UtmParam>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).saveUtmParams(utmParam, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BorrowerPortalApi - factory interface
 * @export
 */
export const BorrowerPortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions for borrower
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactionsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTransactionBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).findAllTransactionsByLoanId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower\'s loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplication(id: number, options?: any): AxiosPromise<LoanApplicationBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan applications for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplications(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanApplicationBorrowerBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerApplications(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower\'s loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoan(id: number, options?: any): AxiosPromise<LoanBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerLoan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loans for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoans(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerLoans(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerProfile(options?: any): AxiosPromise<LegalEntity | Person> {
            return BorrowerPortalApiFp(configuration).getBorrowerProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save borrower\'s UTM parameters
         * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUtmParams(utmParam: Array<UtmParam>, options?: any): AxiosPromise<void> {
            return BorrowerPortalApiFp(configuration).saveUtmParams(utmParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BorrowerPortalApi - object-oriented interface
 * @export
 * @class BorrowerPortalApi
 * @extends {BaseAPI}
 */
export class BorrowerPortalApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan\'s transactions for borrower
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public findAllTransactionsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).findAllTransactionsByLoanId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower\'s loan application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerApplication(id: number, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan applications for borrower
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerApplications(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerApplications(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower\'s loan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerLoan(id: number, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerLoan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loans for borrower
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerLoans(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerLoans(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerProfile(options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save borrower\'s UTM parameters
     * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public saveUtmParams(utmParam: Array<UtmParam>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).saveUtmParams(utmParam, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaign' is not null or undefined
            if (campaign === null || campaign === undefined) {
                throw new RequiredError('campaign','Required parameter campaign was null or undefined when calling createCampaign.');
            }
            const localVarPath = `/api/campaigns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof campaign !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(campaign !== undefined ? campaign : {}) : (campaign || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterMaxAmount] Search by campaign max amount
         * @param {number} [filterMaxTerm] Search by campaign max term
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCampaigns: async (page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaigns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterMaxAmount !== undefined) {
                localVarQueryParameter['filter.maxAmount'] = filterMaxAmount;
            }

            if (filterMaxTerm !== undefined) {
                localVarQueryParameter['filter.maxTerm'] = filterMaxTerm;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter.active'] = filterActive;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCampaign.');
            }
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return campaign by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignByCode: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getCampaignByCode.');
            }
            const localVarPath = `/api/campaigns/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of GiniMachine scoring models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiniMachineScoringModels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaigns/scoring/ginimachine/model`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign: async (id: number, campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCampaign.');
            }
            // verify required parameter 'campaign' is not null or undefined
            if (campaign === null || campaign === undefined) {
                throw new RequiredError('campaign','Required parameter campaign was null or undefined when calling updateCampaign.');
            }
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof campaign !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(campaign !== undefined ? campaign : {}) : (campaign || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).createCampaign(campaign, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterMaxAmount] Search by campaign max amount
         * @param {number} [filterMaxTerm] Search by campaign max term
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCampaigns(page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCampaignBrief>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).findAllCampaigns(page, size, sort, filterMaxAmount, filterMaxTerm, filterActive, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getCampaign(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return campaign by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignByCode(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getCampaignByCode(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of GiniMachine scoring models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiniMachineScoringModels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GiniMachineScoringModel>>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getGiniMachineScoringModels(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaign(id: number, campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).updateCampaign(id, campaign, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).createCampaign(campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterMaxAmount] Search by campaign max amount
         * @param {number} [filterMaxTerm] Search by campaign max term
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCampaigns(page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options?: any): AxiosPromise<PageCampaignBrief> {
            return CampaignApiFp(configuration).findAllCampaigns(page, size, sort, filterMaxAmount, filterMaxTerm, filterActive, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(id: number, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).getCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return campaign by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignByCode(code: string, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).getCampaignByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of GiniMachine scoring models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiniMachineScoringModels(options?: any): AxiosPromise<Array<GiniMachineScoringModel>> {
            return CampaignApiFp(configuration).getGiniMachineScoringModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign(id: number, campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).updateCampaign(id, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Create campaign
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public createCampaign(campaign: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).createCampaign(campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of campaigns
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {number} [filterMaxAmount] Search by campaign max amount
     * @param {number} [filterMaxTerm] Search by campaign max term
     * @param {boolean} [filterActive] Search by campaign active flag
     * @param {string} [filterSearch] Search by campaign name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public findAllCampaigns(page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options?: any) {
        return CampaignApiFp(this.configuration).findAllCampaigns(page, size, sort, filterMaxAmount, filterMaxTerm, filterActive, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return campaign
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaign(id: number, options?: any) {
        return CampaignApiFp(this.configuration).getCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return campaign by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaignByCode(code: string, options?: any) {
        return CampaignApiFp(this.configuration).getCampaignByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of GiniMachine scoring models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getGiniMachineScoringModels(options?: any) {
        return CampaignApiFp(this.configuration).getGiniMachineScoringModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update campaign
     * @param {number} id 
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public updateCampaign(id: number, campaign: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).updateCampaign(id, campaign, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CollectionApi - axios parameter creator
 * @export
 */
export const CollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCollections: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/collections`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterStartedAtFrom !== undefined) {
                localVarQueryParameter['filter.startedAtFrom'] = filterStartedAtFrom;
            }

            if (filterStartedAtTo !== undefined) {
                localVarQueryParameter['filter.startedAtTo'] = filterStartedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCollection.');
            }
            const localVarPath = `/api/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionApi - functional programming interface
 * @export
 */
export const CollectionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCollections(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCollectionBrief>> {
            const localVarAxiosArgs = await CollectionApiAxiosParamCreator(configuration).findAllCollections(page, size, sort, filterStatuses, filterStartedAtFrom, filterStartedAtTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollection(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await CollectionApiAxiosParamCreator(configuration).getCollection(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CollectionApi - factory interface
 * @export
 */
export const CollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCollections(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageCollectionBrief> {
            return CollectionApiFp(configuration).findAllCollections(page, size, sort, filterStatuses, filterStartedAtFrom, filterStartedAtTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection(id: number, options?: any): AxiosPromise<Collection> {
            return CollectionApiFp(configuration).getCollection(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CollectionApi - object-oriented interface
 * @export
 * @class CollectionApi
 * @extends {BaseAPI}
 */
export class CollectionApi extends BaseAPI {
    /**
     * 
     * @summary Return list of collections
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by collection statuses
     * @param {string} [filterStartedAtFrom] Search by collection started at from
     * @param {string} [filterStartedAtTo] Search by collection started at to
     * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public findAllCollections(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options?: any) {
        return CollectionApiFp(this.configuration).findAllCollections(page, size, sort, filterStatuses, filterStartedAtFrom, filterStartedAtTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public getCollection(id: number, options?: any) {
        return CollectionApiFp(this.configuration).getCollection(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DocumentTemplatesApi - axios parameter creator
 * @export
 */
export const DocumentTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/document-templates/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplatesApi - functional programming interface
 * @export
 */
export const DocumentTemplatesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDescription>>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).findAllTemplates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentTemplatesApi - factory interface
 * @export
 */
export const DocumentTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates(options?: any): AxiosPromise<Array<TemplateDescription>> {
            return DocumentTemplatesApiFp(configuration).findAllTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplatesApi - object-oriented interface
 * @export
 * @class DocumentTemplatesApi
 * @extends {BaseAPI}
 */
export class DocumentTemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Return list of available templates in requested folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public findAllTemplates(options?: any) {
        return DocumentTemplatesApiFp(this.configuration).findAllTemplates(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * FileStorageApi - axios parameter creator
 * @export
 */
export const FileStorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (form: string, asAttachment?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            if (form === null || form === undefined) {
                throw new RequiredError('form','Required parameter form was null or undefined when calling download.');
            }
            const localVarPath = `/api/file/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (form !== undefined) {
                localVarQueryParameter['form'] = form;
            }

            if (asAttachment !== undefined) {
                localVarQueryParameter['asAttachment'] = asAttachment;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {any} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (dir: string, name: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dir' is not null or undefined
            if (dir === null || dir === undefined) {
                throw new RequiredError('dir','Required parameter dir was null or undefined when calling upload.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling upload.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling upload.');
            }
            const localVarPath = `/api/file/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dir !== undefined) {
                localVarQueryParameter['dir'] = dir;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileStorageApi - functional programming interface
 * @export
 */
export const FileStorageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(form: string, asAttachment?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await FileStorageApiAxiosParamCreator(configuration).download(form, asAttachment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {any} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(dir: string, name: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadResponse>> {
            const localVarAxiosArgs = await FileStorageApiAxiosParamCreator(configuration).upload(dir, name, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FileStorageApi - factory interface
 * @export
 */
export const FileStorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(form: string, asAttachment?: boolean, options?: any): AxiosPromise<any> {
            return FileStorageApiFp(configuration).download(form, asAttachment, options).then((request) => request(axios, basePath));
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {any} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(dir: string, name: string, file: any, options?: any): AxiosPromise<FileUploadResponse> {
            return FileStorageApiFp(configuration).upload(dir, name, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileStorageApi - object-oriented interface
 * @export
 * @class FileStorageApi
 * @extends {BaseAPI}
 */
export class FileStorageApi extends BaseAPI {
    /**
     * Retrieves a file from configured storage
     * @summary File download
     * @param {string} form The file name to retrieve
     * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public download(form: string, asAttachment?: boolean, options?: any) {
        return FileStorageApiFp(this.configuration).download(form, asAttachment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receives a file and stores it in configured storage
     * @summary File upload
     * @param {string} dir The directory to store file into
     * @param {string} name The file name
     * @param {any} file The file content to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public upload(dir: string, name: string, file: any, options?: any) {
        return FileStorageApiFp(this.configuration).upload(dir, name, file, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a loan based on the loan application
         * @param {RequestLoanManagement} requestLoanManagement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageLoan: async (requestLoanManagement: RequestLoanManagement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestLoanManagement' is not null or undefined
            if (requestLoanManagement === null || requestLoanManagement === undefined) {
                throw new RequiredError('requestLoanManagement','Required parameter requestLoanManagement was null or undefined when calling manageLoan.');
            }
            const localVarPath = `/api/integration/v1/manage-loan`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestLoanManagement !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestLoanManagement !== undefined ? requestLoanManagement : {}) : (requestLoanManagement || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Originate and manage loan application with borrower as legal entity
         * @param {Array} balanceSheet List of Balance sheet files
         * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
         * @param {Array} pnlStatement List of Profit and loss statement files
         * @param {RequestLegalEntityLoanOrigination} request 
         * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateAndManageLoanLegalEntity: async (balanceSheet: Array, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, callbackUrl?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'balanceSheet' is not null or undefined
            if (balanceSheet === null || balanceSheet === undefined) {
                throw new RequiredError('balanceSheet','Required parameter balanceSheet was null or undefined when calling originateAndManageLoanLegalEntity.');
            }
            // verify required parameter 'idDocument' is not null or undefined
            if (idDocument === null || idDocument === undefined) {
                throw new RequiredError('idDocument','Required parameter idDocument was null or undefined when calling originateAndManageLoanLegalEntity.');
            }
            // verify required parameter 'pnlStatement' is not null or undefined
            if (pnlStatement === null || pnlStatement === undefined) {
                throw new RequiredError('pnlStatement','Required parameter pnlStatement was null or undefined when calling originateAndManageLoanLegalEntity.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling originateAndManageLoanLegalEntity.');
            }
            const localVarPath = `/api/integration/v1/originate-and-manage-loan-legal-entity`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (balanceSheet !== undefined) { 
                localVarFormParams.append('balanceSheet', balanceSheet as any);
            }
    
            if (callbackUrl !== undefined) { 
                localVarFormParams.append('callbackUrl', callbackUrl as any);
            }
    
            if (idDocument !== undefined) { 
                localVarFormParams.append('idDocument', idDocument as any);
            }
    
            if (pnlStatement !== undefined) { 
                localVarFormParams.append('pnlStatement', pnlStatement as any);
            }
    
            if (request !== undefined) { 
                localVarFormParams.append('request', request as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Originate and manage loan application with borrower as person
         * @param {Array} idDocument List of the identification document\\\&#39;s files
         * @param {RequestPersonLoanOrigination} request 
         * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateAndManageLoanPerson: async (idDocument: Array, request: RequestPersonLoanOrigination, callbackUrl?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'idDocument' is not null or undefined
            if (idDocument === null || idDocument === undefined) {
                throw new RequiredError('idDocument','Required parameter idDocument was null or undefined when calling originateAndManageLoanPerson.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling originateAndManageLoanPerson.');
            }
            const localVarPath = `/api/integration/v1/originate-and-manage-loan-person`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (callbackUrl !== undefined) { 
                localVarFormParams.append('callbackUrl', callbackUrl as any);
            }
    
            if (idDocument !== undefined) { 
                localVarFormParams.append('idDocument', idDocument as any);
            }
    
            if (request !== undefined) { 
                localVarFormParams.append('request', request as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Originate loan application with borrower as legal entity
         * @param {Array} balanceSheet List of Balance sheet files
         * @param {string} callbackUrl URL for sending a callback with a processed loan application
         * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
         * @param {Array} pnlStatement List of Profit and loss statement files
         * @param {RequestLegalEntityLoanOrigination} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateLoanLegalEntity: async (balanceSheet: Array, callbackUrl: string, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'balanceSheet' is not null or undefined
            if (balanceSheet === null || balanceSheet === undefined) {
                throw new RequiredError('balanceSheet','Required parameter balanceSheet was null or undefined when calling originateLoanLegalEntity.');
            }
            // verify required parameter 'callbackUrl' is not null or undefined
            if (callbackUrl === null || callbackUrl === undefined) {
                throw new RequiredError('callbackUrl','Required parameter callbackUrl was null or undefined when calling originateLoanLegalEntity.');
            }
            // verify required parameter 'idDocument' is not null or undefined
            if (idDocument === null || idDocument === undefined) {
                throw new RequiredError('idDocument','Required parameter idDocument was null or undefined when calling originateLoanLegalEntity.');
            }
            // verify required parameter 'pnlStatement' is not null or undefined
            if (pnlStatement === null || pnlStatement === undefined) {
                throw new RequiredError('pnlStatement','Required parameter pnlStatement was null or undefined when calling originateLoanLegalEntity.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling originateLoanLegalEntity.');
            }
            const localVarPath = `/api/integration/v1/originate-loan-legal-entity`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (balanceSheet !== undefined) { 
                localVarFormParams.append('balanceSheet', balanceSheet as any);
            }
    
            if (callbackUrl !== undefined) { 
                localVarFormParams.append('callbackUrl', callbackUrl as any);
            }
    
            if (idDocument !== undefined) { 
                localVarFormParams.append('idDocument', idDocument as any);
            }
    
            if (pnlStatement !== undefined) { 
                localVarFormParams.append('pnlStatement', pnlStatement as any);
            }
    
            if (request !== undefined) { 
                localVarFormParams.append('request', request as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Originate loan application with borrower as person
         * @param {string} callbackUrl URL for sending a callback with a processed loan application
         * @param {Array} idDocument List of the identification document\\\&#39;s files
         * @param {RequestPersonLoanOrigination} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateLoanPerson: async (callbackUrl: string, idDocument: Array, request: RequestPersonLoanOrigination, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'callbackUrl' is not null or undefined
            if (callbackUrl === null || callbackUrl === undefined) {
                throw new RequiredError('callbackUrl','Required parameter callbackUrl was null or undefined when calling originateLoanPerson.');
            }
            // verify required parameter 'idDocument' is not null or undefined
            if (idDocument === null || idDocument === undefined) {
                throw new RequiredError('idDocument','Required parameter idDocument was null or undefined when calling originateLoanPerson.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling originateLoanPerson.');
            }
            const localVarPath = `/api/integration/v1/originate-loan-person`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (callbackUrl !== undefined) { 
                localVarFormParams.append('callbackUrl', callbackUrl as any);
            }
    
            if (idDocument !== undefined) { 
                localVarFormParams.append('idDocument', idDocument as any);
            }
    
            if (request !== undefined) { 
                localVarFormParams.append('request', request as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a loan based on the loan application
         * @param {RequestLoanManagement} requestLoanManagement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manageLoan(requestLoanManagement: RequestLoanManagement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanApplicationBorrower>> {
            const localVarAxiosArgs = await IntegrationApiAxiosParamCreator(configuration).manageLoan(requestLoanManagement, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Originate and manage loan application with borrower as legal entity
         * @param {Array} balanceSheet List of Balance sheet files
         * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
         * @param {Array} pnlStatement List of Profit and loss statement files
         * @param {RequestLegalEntityLoanOrigination} request 
         * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async originateAndManageLoanLegalEntity(balanceSheet: Array, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, callbackUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await IntegrationApiAxiosParamCreator(configuration).originateAndManageLoanLegalEntity(balanceSheet, idDocument, pnlStatement, request, callbackUrl, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Originate and manage loan application with borrower as person
         * @param {Array} idDocument List of the identification document\\\&#39;s files
         * @param {RequestPersonLoanOrigination} request 
         * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async originateAndManageLoanPerson(idDocument: Array, request: RequestPersonLoanOrigination, callbackUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await IntegrationApiAxiosParamCreator(configuration).originateAndManageLoanPerson(idDocument, request, callbackUrl, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Originate loan application with borrower as legal entity
         * @param {Array} balanceSheet List of Balance sheet files
         * @param {string} callbackUrl URL for sending a callback with a processed loan application
         * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
         * @param {Array} pnlStatement List of Profit and loss statement files
         * @param {RequestLegalEntityLoanOrigination} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async originateLoanLegalEntity(balanceSheet: Array, callbackUrl: string, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await IntegrationApiAxiosParamCreator(configuration).originateLoanLegalEntity(balanceSheet, callbackUrl, idDocument, pnlStatement, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Originate loan application with borrower as person
         * @param {string} callbackUrl URL for sending a callback with a processed loan application
         * @param {Array} idDocument List of the identification document\\\&#39;s files
         * @param {RequestPersonLoanOrigination} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async originateLoanPerson(callbackUrl: string, idDocument: Array, request: RequestPersonLoanOrigination, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await IntegrationApiAxiosParamCreator(configuration).originateLoanPerson(callbackUrl, idDocument, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a loan based on the loan application
         * @param {RequestLoanManagement} requestLoanManagement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageLoan(requestLoanManagement: RequestLoanManagement, options?: any): AxiosPromise<LoanApplicationBorrower> {
            return IntegrationApiFp(configuration).manageLoan(requestLoanManagement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Originate and manage loan application with borrower as legal entity
         * @param {Array} balanceSheet List of Balance sheet files
         * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
         * @param {Array} pnlStatement List of Profit and loss statement files
         * @param {RequestLegalEntityLoanOrigination} request 
         * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateAndManageLoanLegalEntity(balanceSheet: Array, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, callbackUrl?: string, options?: any): AxiosPromise<string> {
            return IntegrationApiFp(configuration).originateAndManageLoanLegalEntity(balanceSheet, idDocument, pnlStatement, request, callbackUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Originate and manage loan application with borrower as person
         * @param {Array} idDocument List of the identification document\\\&#39;s files
         * @param {RequestPersonLoanOrigination} request 
         * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateAndManageLoanPerson(idDocument: Array, request: RequestPersonLoanOrigination, callbackUrl?: string, options?: any): AxiosPromise<string> {
            return IntegrationApiFp(configuration).originateAndManageLoanPerson(idDocument, request, callbackUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Originate loan application with borrower as legal entity
         * @param {Array} balanceSheet List of Balance sheet files
         * @param {string} callbackUrl URL for sending a callback with a processed loan application
         * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
         * @param {Array} pnlStatement List of Profit and loss statement files
         * @param {RequestLegalEntityLoanOrigination} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateLoanLegalEntity(balanceSheet: Array, callbackUrl: string, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, options?: any): AxiosPromise<string> {
            return IntegrationApiFp(configuration).originateLoanLegalEntity(balanceSheet, callbackUrl, idDocument, pnlStatement, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Originate loan application with borrower as person
         * @param {string} callbackUrl URL for sending a callback with a processed loan application
         * @param {Array} idDocument List of the identification document\\\&#39;s files
         * @param {RequestPersonLoanOrigination} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        originateLoanPerson(callbackUrl: string, idDocument: Array, request: RequestPersonLoanOrigination, options?: any): AxiosPromise<string> {
            return IntegrationApiFp(configuration).originateLoanPerson(callbackUrl, idDocument, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Create a loan based on the loan application
     * @param {RequestLoanManagement} requestLoanManagement 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public manageLoan(requestLoanManagement: RequestLoanManagement, options?: any) {
        return IntegrationApiFp(this.configuration).manageLoan(requestLoanManagement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Originate and manage loan application with borrower as legal entity
     * @param {Array} balanceSheet List of Balance sheet files
     * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
     * @param {Array} pnlStatement List of Profit and loss statement files
     * @param {RequestLegalEntityLoanOrigination} request 
     * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public originateAndManageLoanLegalEntity(balanceSheet: Array, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, callbackUrl?: string, options?: any) {
        return IntegrationApiFp(this.configuration).originateAndManageLoanLegalEntity(balanceSheet, idDocument, pnlStatement, request, callbackUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Originate and manage loan application with borrower as person
     * @param {Array} idDocument List of the identification document\\\&#39;s files
     * @param {RequestPersonLoanOrigination} request 
     * @param {string} [callbackUrl] URL for sending a callback with a processed loan application. Callback will not performed if the parameter not provided
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public originateAndManageLoanPerson(idDocument: Array, request: RequestPersonLoanOrigination, callbackUrl?: string, options?: any) {
        return IntegrationApiFp(this.configuration).originateAndManageLoanPerson(idDocument, request, callbackUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Originate loan application with borrower as legal entity
     * @param {Array} balanceSheet List of Balance sheet files
     * @param {string} callbackUrl URL for sending a callback with a processed loan application
     * @param {Array} idDocument List of files of the document identifying the representative of the legal entity
     * @param {Array} pnlStatement List of Profit and loss statement files
     * @param {RequestLegalEntityLoanOrigination} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public originateLoanLegalEntity(balanceSheet: Array, callbackUrl: string, idDocument: Array, pnlStatement: Array, request: RequestLegalEntityLoanOrigination, options?: any) {
        return IntegrationApiFp(this.configuration).originateLoanLegalEntity(balanceSheet, callbackUrl, idDocument, pnlStatement, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Originate loan application with borrower as person
     * @param {string} callbackUrl URL for sending a callback with a processed loan application
     * @param {Array} idDocument List of the identification document\\\&#39;s files
     * @param {RequestPersonLoanOrigination} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public originateLoanPerson(callbackUrl: string, idDocument: Array, request: RequestPersonLoanOrigination, options?: any) {
        return IntegrationApiFp(this.configuration).originateLoanPerson(callbackUrl, idDocument, request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LeadApi - axios parameter creator
 * @export
 */
export const LeadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create lead
         * @param {Lead} lead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLead: async (lead: Lead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lead' is not null or undefined
            if (lead === null || lead === undefined) {
                throw new RequiredError('lead','Required parameter lead was null or undefined when calling createLead.');
            }
            const localVarPath = `/api/leads`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof lead !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(lead !== undefined ? lead : {}) : (lead || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of leads
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by lead\&#39;s fullName, phone, email
         * @param {string} [filterAgentLegalName] Search by agent\&#39;s legal name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLeads: async (page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterAgentLegalName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/leads`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }

            if (filterAgentLegalName !== undefined) {
                localVarQueryParameter['filter.agentLegalName'] = filterAgentLegalName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single lead
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLead.');
            }
            const localVarPath = `/api/leads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update lead
         * @param {number} id 
         * @param {Lead} lead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLead: async (id: number, lead: Lead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLead.');
            }
            // verify required parameter 'lead' is not null or undefined
            if (lead === null || lead === undefined) {
                throw new RequiredError('lead','Required parameter lead was null or undefined when calling updateLead.');
            }
            const localVarPath = `/api/leads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof lead !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(lead !== undefined ? lead : {}) : (lead || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadApi - functional programming interface
 * @export
 */
export const LeadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create lead
         * @param {Lead} lead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLead(lead: Lead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await LeadApiAxiosParamCreator(configuration).createLead(lead, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of leads
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by lead\&#39;s fullName, phone, email
         * @param {string} [filterAgentLegalName] Search by agent\&#39;s legal name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLeads(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterAgentLegalName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLeadBrief>> {
            const localVarAxiosArgs = await LeadApiAxiosParamCreator(configuration).findAllLeads(page, size, sort, filterSearch, filterAgentLegalName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single lead
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await LeadApiAxiosParamCreator(configuration).getLead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update lead
         * @param {number} id 
         * @param {Lead} lead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLead(id: number, lead: Lead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
            const localVarAxiosArgs = await LeadApiAxiosParamCreator(configuration).updateLead(id, lead, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LeadApi - factory interface
 * @export
 */
export const LeadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create lead
         * @param {Lead} lead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLead(lead: Lead, options?: any): AxiosPromise<Lead> {
            return LeadApiFp(configuration).createLead(lead, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of leads
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by lead\&#39;s fullName, phone, email
         * @param {string} [filterAgentLegalName] Search by agent\&#39;s legal name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLeads(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterAgentLegalName?: string, options?: any): AxiosPromise<PageLeadBrief> {
            return LeadApiFp(configuration).findAllLeads(page, size, sort, filterSearch, filterAgentLegalName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single lead
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLead(id: number, options?: any): AxiosPromise<Lead> {
            return LeadApiFp(configuration).getLead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update lead
         * @param {number} id 
         * @param {Lead} lead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLead(id: number, lead: Lead, options?: any): AxiosPromise<Lead> {
            return LeadApiFp(configuration).updateLead(id, lead, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadApi - object-oriented interface
 * @export
 * @class LeadApi
 * @extends {BaseAPI}
 */
export class LeadApi extends BaseAPI {
    /**
     * 
     * @summary Create lead
     * @param {Lead} lead 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public createLead(lead: Lead, options?: any) {
        return LeadApiFp(this.configuration).createLead(lead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of leads
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [filterSearch] Search by lead\&#39;s fullName, phone, email
     * @param {string} [filterAgentLegalName] Search by agent\&#39;s legal name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public findAllLeads(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterAgentLegalName?: string, options?: any) {
        return LeadApiFp(this.configuration).findAllLeads(page, size, sort, filterSearch, filterAgentLegalName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single lead
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public getLead(id: number, options?: any) {
        return LeadApiFp(this.configuration).getLead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update lead
     * @param {number} id 
     * @param {Lead} lead 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public updateLead(id: number, lead: Lead, options?: any) {
        return LeadApiFp(this.configuration).updateLead(id, lead, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanApi - axios parameter creator
 * @export
 */
export const LoanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of agent\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAgentId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByAgentId.');
            }
            const localVarPath = `/api/loans/agent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByBorrowerId.');
            }
            const localVarPath = `/api/loans/borrower/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoans: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loans`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterIssueDateFrom !== undefined) {
                localVarQueryParameter['filter.issueDateFrom'] = filterIssueDateFrom;
            }

            if (filterIssueDateTo !== undefined) {
                localVarQueryParameter['filter.issueDateTo'] = filterIssueDateTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOperationsByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllOperationsByLoanId.');
            }
            const localVarPath = `/api/loans/{id}/operations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOperationById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findOperationById.');
            }
            const localVarPath = `/api/loans/loan/operations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLoan.');
            }
            const localVarPath = `/api/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApi - functional programming interface
 * @export
 */
export const LoanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of agent\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByAgentId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findAllByAgentId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findAllByBorrowerId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findAllLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllOperationsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanOperation>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findAllOperationsByLoanId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOperationById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOperationRepresentation>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findOperationById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Loan>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).getLoan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanApi - factory interface
 * @export
 */
export const LoanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of agent\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAgentId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanBrief> {
            return LoanApiFp(configuration).findAllByAgentId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanBrief> {
            return LoanApiFp(configuration).findAllByBorrowerId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageLoanBrief> {
            return LoanApiFp(configuration).findAllLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOperationsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanOperation> {
            return LoanApiFp(configuration).findAllOperationsByLoanId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOperationById(id: number, options?: any): AxiosPromise<LoanOperationRepresentation> {
            return LoanApiFp(configuration).findOperationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan(id: number, options?: any): AxiosPromise<Loan> {
            return LoanApiFp(configuration).getLoan(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanApi - object-oriented interface
 * @export
 * @class LoanApi
 * @extends {BaseAPI}
 */
export class LoanApi extends BaseAPI {
    /**
     * 
     * @summary Return list of agent\'s loans
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllByAgentId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApiFp(this.configuration).findAllByAgentId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of borrower\'s loans
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApiFp(this.configuration).findAllByBorrowerId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loans
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by loan statuses
     * @param {string} [filterIssueDateFrom] Search by loan issue date from
     * @param {string} [filterIssueDateTo] Search by loan issue date to
     * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any) {
        return LoanApiFp(this.configuration).findAllLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan\'s operations
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllOperationsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApiFp(this.configuration).findAllOperationsByLoanId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan\'s operations
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findOperationById(id: number, options?: any) {
        return LoanApiFp(this.configuration).findOperationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public getLoan(id: number, options?: any) {
        return LoanApiFp(this.configuration).getLoan(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanApplicationApi - axios parameter creator
 * @export
 */
export const LoanApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllApplications: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan-applications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterRequestedAtFrom !== undefined) {
                localVarQueryParameter['filter.requestedAtFrom'] = filterRequestedAtFrom;
            }

            if (filterRequestedAtTo !== undefined) {
                localVarQueryParameter['filter.requestedAtTo'] = filterRequestedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of agent\'s loan applications
         * @param {number} id Agent id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAgentId1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByAgentId1.');
            }
            const localVarPath = `/api/loan-applications/agent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByBorrowerId1.');
            }
            const localVarPath = `/api/loan-applications/borrower/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getApplication.');
            }
            const localVarPath = `/api/loan-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail: async (id: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAuditTrail.');
            }
            const localVarPath = `/api/loan-applications/{id}/audit-trail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApplicationApi - functional programming interface
 * @export
 */
export const LoanApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBorrowerBrief>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).findAllApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of agent\'s loan applications
         * @param {number} id Agent id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByAgentId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBorrowerBrief>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).findAllByAgentId1(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByBorrowerId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBorrowerBrief>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).findAllByBorrowerId1(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanApplication>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).getApplication(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditTrail(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageActivityAuditTrail>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).getAuditTrail(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanApplicationApi - factory interface
 * @export
 */
export const LoanApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageLoanApplicationBorrowerBrief> {
            return LoanApplicationApiFp(configuration).findAllApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of agent\'s loan applications
         * @param {number} id Agent id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByAgentId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanApplicationBorrowerBrief> {
            return LoanApplicationApiFp(configuration).findAllByAgentId1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanApplicationBorrowerBrief> {
            return LoanApplicationApiFp(configuration).findAllByBorrowerId1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(id: number, options?: any): AxiosPromise<LoanApplication> {
            return LoanApplicationApiFp(configuration).getApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageActivityAuditTrail> {
            return LoanApplicationApiFp(configuration).getAuditTrail(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanApplicationApi - object-oriented interface
 * @export
 * @class LoanApplicationApi
 * @extends {BaseAPI}
 */
export class LoanApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan applications
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by loan application statuses
     * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
     * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
     * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findAllApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any) {
        return LoanApplicationApiFp(this.configuration).findAllApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of agent\'s loan applications
     * @param {number} id Agent id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findAllByAgentId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApplicationApiFp(this.configuration).findAllByAgentId1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of borrower\'s loan applications
     * @param {number} id Borrower id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findAllByBorrowerId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApplicationApiFp(this.configuration).findAllByBorrowerId1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public getApplication(id: number, options?: any) {
        return LoanApplicationApiFp(this.configuration).getApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return audit trail for the loan application
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public getAuditTrail(id: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApplicationApiFp(this.configuration).getAuditTrail(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanProductApi - axios parameter creator
 * @export
 */
export const LoanProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (loanProduct: LoanProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanProduct' is not null or undefined
            if (loanProduct === null || loanProduct === undefined) {
                throw new RequiredError('loanProduct','Required parameter loanProduct was null or undefined when calling createProduct.');
            }
            const localVarPath = `/api/loan-products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanProduct !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanProduct !== undefined ? loanProduct : {}) : (loanProduct || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCampaignId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByCampaignId.');
            }
            const localVarPath = `/api/loan-products/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts: async (page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan-products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterAmount !== undefined) {
                localVarQueryParameter['filter.amount'] = filterAmount;
            }

            if (filterTerm !== undefined) {
                localVarQueryParameter['filter.term'] = filterTerm;
            }

            if (filterCampaignId !== undefined) {
                localVarQueryParameter['filter.campaignId'] = filterCampaignId;
            }

            if (filterPaymentFrequency !== undefined) {
                localVarQueryParameter['filter.paymentFrequency'] = filterPaymentFrequency;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter.active'] = filterActive;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }

            if (filterInterestRate !== undefined) {
                localVarQueryParameter['filter.interestRate'] = filterInterestRate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHistory.');
            }
            const localVarPath = `/api/loan-products/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProduct.');
            }
            const localVarPath = `/api/loan-products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: number, loanProduct: LoanProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProduct.');
            }
            // verify required parameter 'loanProduct' is not null or undefined
            if (loanProduct === null || loanProduct === undefined) {
                throw new RequiredError('loanProduct','Required parameter loanProduct was null or undefined when calling updateProduct.');
            }
            const localVarPath = `/api/loan-products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanProduct !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanProduct !== undefined ? loanProduct : {}) : (loanProduct || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanProductApi - functional programming interface
 * @export
 */
export const LoanProductApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(loanProduct: LoanProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).createProduct(loanProduct, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByCampaignId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).findAllByCampaignId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllProducts(page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).findAllProducts(page, size, sort, filterAmount, filterTerm, filterCampaignId, filterPaymentFrequency, filterActive, filterSearch, filterInterestRate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerLoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).getHistory(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).getProduct(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: number, loanProduct: LoanProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).updateProduct(id, loanProduct, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanProductApi - factory interface
 * @export
 */
export const LoanProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(loanProduct: LoanProduct, options?: any): AxiosPromise<LoanProduct> {
            return LoanProductApiFp(configuration).createProduct(loanProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCampaignId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanProduct> {
            return LoanProductApiFp(configuration).findAllByCampaignId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts(page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options?: any): AxiosPromise<PageLoanProduct> {
            return LoanProductApiFp(configuration).findAllProducts(page, size, sort, filterAmount, filterTerm, filterCampaignId, filterPaymentFrequency, filterActive, filterSearch, filterInterestRate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageRevisionIntegerLoanProduct> {
            return LoanProductApiFp(configuration).getHistory(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(id: number, options?: any): AxiosPromise<LoanProduct> {
            return LoanProductApiFp(configuration).getProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(id: number, loanProduct: LoanProduct, options?: any): AxiosPromise<LoanProduct> {
            return LoanProductApiFp(configuration).updateProduct(id, loanProduct, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanProductApi - object-oriented interface
 * @export
 * @class LoanProductApi
 * @extends {BaseAPI}
 */
export class LoanProductApi extends BaseAPI {
    /**
     * 
     * @summary Create loan product
     * @param {LoanProduct} loanProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public createProduct(loanProduct: LoanProduct, options?: any) {
        return LoanProductApiFp(this.configuration).createProduct(loanProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of campaign\'s loan products
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public findAllByCampaignId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanProductApiFp(this.configuration).findAllByCampaignId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan products
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {number} [filterAmount] Search by loan product amount
     * @param {number} [filterTerm] Search by loan product term
     * @param {number} [filterCampaignId] Search by loan campaign id
     * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
     * @param {boolean} [filterActive] Search by loan product active flag
     * @param {string} [filterSearch] Search by loan product code
     * @param {number} [filterInterestRate] Search by loan product interest rate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public findAllProducts(page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options?: any) {
        return LoanProductApiFp(this.configuration).findAllProducts(page, size, sort, filterAmount, filterTerm, filterCampaignId, filterPaymentFrequency, filterActive, filterSearch, filterInterestRate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return loan product\'s history
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public getHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanProductApiFp(this.configuration).getHistory(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan product
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public getProduct(id: number, options?: any) {
        return LoanProductApiFp(this.configuration).getProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan product
     * @param {number} id 
     * @param {LoanProduct} loanProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public updateProduct(id: number, loanProduct: LoanProduct, options?: any) {
        return LoanProductApiFp(this.configuration).updateProduct(id, loanProduct, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProcessDefinitionManagerApi - axios parameter creator
 * @export
 */
export const ProcessDefinitionManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGet: async (key: string, variables: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling calculateGet.');
            }
            // verify required parameter 'variables' is not null or undefined
            if (variables === null || variables === undefined) {
                throw new RequiredError('variables','Required parameter variables was null or undefined when calling calculateGet.');
            }
            const localVarPath = `/api/process-definition/key/{key}/calculate`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (variables !== undefined) {
                localVarQueryParameter['variables'] = variables;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePost: async (key: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling calculatePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling calculatePost.');
            }
            const localVarPath = `/api/process-definition/key/{key}/calculate`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinition: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getProcessDefinition.');
            }
            const localVarPath = `/api/process-definition/key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartForm: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getStartForm.');
            }
            const localVarPath = `/api/process-definition/key/{key}/start-form`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormVariables: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getStartFormVariables.');
            }
            const localVarPath = `/api/process-definition/key/{key}/form-variables`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormWithData: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getStartFormWithData.');
            }
            const localVarPath = `/api/process-definition/key/{key}/start-form-with-data`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStartableByUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/process-definition`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitStartFrom: async (key: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling submitStartFrom.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling submitStartFrom.');
            }
            const localVarPath = `/api/process-definition/key/{key}/submit-form`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessDefinitionManagerApi - functional programming interface
 * @export
 */
export const ProcessDefinitionManagerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateGet(key: string, variables: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).calculateGet(key, variables, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePost(key: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).calculatePost(key, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessDefinition(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessDefinitionRepresentation>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getProcessDefinition(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartForm(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getStartForm(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartFormVariables(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getStartFormVariables(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartFormWithData(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getStartFormWithData(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStartableByUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProcessDefinitionRepresentation>>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).listStartableByUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitStartFrom(key: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskRepresentation>>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).submitStartFrom(key, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProcessDefinitionManagerApi - factory interface
 * @export
 */
export const ProcessDefinitionManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGet(key: string, variables: { [key: string]: object; }, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return ProcessDefinitionManagerApiFp(configuration).calculateGet(key, variables, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePost(key: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return ProcessDefinitionManagerApiFp(configuration).calculatePost(key, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinition(key: string, options?: any): AxiosPromise<ProcessDefinitionRepresentation> {
            return ProcessDefinitionManagerApiFp(configuration).getProcessDefinition(key, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartForm(key: string, options?: any): AxiosPromise<object> {
            return ProcessDefinitionManagerApiFp(configuration).getStartForm(key, options).then((request) => request(axios, basePath));
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormVariables(key: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return ProcessDefinitionManagerApiFp(configuration).getStartFormVariables(key, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormWithData(key: string, options?: any): AxiosPromise<object> {
            return ProcessDefinitionManagerApiFp(configuration).getStartFormWithData(key, options).then((request) => request(axios, basePath));
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStartableByUser(options?: any): AxiosPromise<Array<ProcessDefinitionRepresentation>> {
            return ProcessDefinitionManagerApiFp(configuration).listStartableByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitStartFrom(key: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<TaskRepresentation>> {
            return ProcessDefinitionManagerApiFp(configuration).submitStartFrom(key, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessDefinitionManagerApi - object-oriented interface
 * @export
 * @class ProcessDefinitionManagerApi
 * @extends {BaseAPI}
 */
export class ProcessDefinitionManagerApi extends BaseAPI {
    /**
     * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
     * @summary Start a process instance with result in return
     * @param {string} key Process definition key
     * @param {{ [key: string]: object; }} variables Input variables
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public calculateGet(key: string, variables: { [key: string]: object; }, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).calculateGet(key, variables, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
     * @summary Start a process instance with result in return
     * @param {string} key Process definition key
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public calculatePost(key: string, requestBody: { [key: string]: object; }, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).calculatePost(key, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List process definitions allowed to start by the user performed the request
     * @summary List process definitions allowed to start
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getProcessDefinition(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getProcessDefinition(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
     * @summary Retrieve start form for the process
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartForm(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartForm(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of start form variables of a latest process definition if allowed to access.
     * @summary Retrieve start form variables
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartFormVariables(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartFormVariables(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
     * @summary Retrieve start form for the process with form variables included
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartFormWithData(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartFormWithData(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List process definitions allowed to start by the user performed the request
     * @summary List process definitions allowed to start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public listStartableByUser(options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).listStartableByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a process instance of latest definition by form submission if allowed to access.
     * @summary Start a process instance by form submission
     * @param {string} key Process definition key
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public submitStartFrom(key: string, requestBody: { [key: string]: object; }, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).submitStartFrom(key, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TaskManagerApi - axios parameter creator
 * @export
 */
export const TaskManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claim: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling claim.');
            }
            const localVarPath = `/api/task/{id}/claim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskForm: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskForm.');
            }
            const localVarPath = `/api/task/{id}/form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormVariables: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskFormVariables.');
            }
            const localVarPath = `/api/task/{id}/form-variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormWithData: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskFormWithData.');
            }
            const localVarPath = `/api/task/{id}/form-with-data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskInfo.');
            }
            const localVarPath = `/api/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssigned: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/assigned`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailable: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/available`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm: async (id: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling submitForm.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling submitForm.');
            }
            const localVarPath = `/api/task/{id}/submit-form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaim: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unclaim.');
            }
            const localVarPath = `/api/task/{id}/unclaim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskManagerApi - functional programming interface
 * @export
 */
export const TaskManagerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claim(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).claim(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskForm(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskForm(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskFormVariables(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskFormVariables(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskFormWithData(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskFormWithData(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskRepresentation>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskInfo(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssigned(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTaskRepresentation>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).listAssigned(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailable(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTaskRepresentation>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).listAvailable(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitForm(id: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskRepresentation>>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).submitForm(id, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unclaim(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).unclaim(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TaskManagerApi - factory interface
 * @export
 */
export const TaskManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claim(id: string, options?: any): AxiosPromise<void> {
            return TaskManagerApiFp(configuration).claim(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskForm(id: string, options?: any): AxiosPromise<object> {
            return TaskManagerApiFp(configuration).getTaskForm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormVariables(id: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return TaskManagerApiFp(configuration).getTaskFormVariables(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormWithData(id: string, options?: any): AxiosPromise<object> {
            return TaskManagerApiFp(configuration).getTaskFormWithData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(id: string, options?: any): AxiosPromise<TaskRepresentation> {
            return TaskManagerApiFp(configuration).getTaskInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssigned(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTaskRepresentation> {
            return TaskManagerApiFp(configuration).listAssigned(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailable(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTaskRepresentation> {
            return TaskManagerApiFp(configuration).listAvailable(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm(id: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<TaskRepresentation>> {
            return TaskManagerApiFp(configuration).submitForm(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaim(id: string, options?: any): AxiosPromise<void> {
            return TaskManagerApiFp(configuration).unclaim(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskManagerApi - object-oriented interface
 * @export
 * @class TaskManagerApi
 * @extends {BaseAPI}
 */
export class TaskManagerApi extends BaseAPI {
    /**
     * Assign the task to current user if available.
     * @summary Claim a task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public claim(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).claim(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
     * @summary Retrieve form for the task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskForm(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskForm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of task form variables of a latest process definition if allowed to access.
     * @summary Retrieve task form variables
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskFormVariables(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskFormVariables(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
     * @summary Retrieve form with data for the task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskFormWithData(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskFormWithData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve brief info for a single task if available.
     * @summary Get task info
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskInfo(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List tasks assigned to a user performed the request
     * @summary List assigned tasks
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public listAssigned(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TaskManagerApiFp(this.configuration).listAssigned(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
     * @summary List tasks available for claim
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public listAvailable(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TaskManagerApiFp(this.configuration).listAvailable(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Complete the task by form submission if the user is assignee.
     * @summary Complete a task by form submission
     * @param {string} id Task id
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public submitForm(id: string, requestBody: { [key: string]: object; }, options?: any) {
        return TaskManagerApiFp(this.configuration).submitForm(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unassign the task from current user.
     * @summary Unclaim a task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public unclaim(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).unclaim(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByLoanId.');
            }
            const localVarPath = `/api/transactions/loan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactions: async (page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterTypes) {
                localVarQueryParameter['filter.types'] = filterTypes;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterCreatedAtFrom !== undefined) {
                localVarQueryParameter['filter.createdAtFrom'] = filterCreatedAtFrom;
            }

            if (filterCreatedAtTo !== undefined) {
                localVarQueryParameter['filter.createdAtTo'] = filterCreatedAtTo;
            }

            if (filterProcessedAtFrom !== undefined) {
                localVarQueryParameter['filter.processedAtFrom'] = filterProcessedAtFrom;
            }

            if (filterProcessedAtTo !== undefined) {
                localVarQueryParameter['filter.processedAtTo'] = filterProcessedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTransaction.');
            }
            const localVarPath = `/api/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransactionBrief>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).findAllByLoanId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTransactions(page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransactionBrief>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).findAllTransactions(page, size, sort, filterTypes, filterStatuses, filterCreatedAtFrom, filterCreatedAtTo, filterProcessedAtFrom, filterProcessedAtTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).getTransaction(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTransactionBrief> {
            return TransactionApiFp(configuration).findAllByLoanId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactions(page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageTransactionBrief> {
            return TransactionApiFp(configuration).findAllTransactions(page, size, sort, filterTypes, filterStatuses, filterCreatedAtFrom, filterCreatedAtTo, filterProcessedAtFrom, filterProcessedAtTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(id: number, options?: any): AxiosPromise<Transaction> {
            return TransactionApiFp(configuration).getTransaction(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan\'s transactions
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public findAllByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TransactionApiFp(this.configuration).findAllByLoanId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of transactions
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterTypes] Search by transaction types
     * @param {Array<string>} [filterStatuses] Search by transaction statuses
     * @param {string} [filterCreatedAtFrom] Search by transaction created at from
     * @param {string} [filterCreatedAtTo] Search by transaction created at to
     * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
     * @param {string} [filterProcessedAtTo] Search by transaction processed at to
     * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public findAllTransactions(page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options?: any) {
        return TransactionApiFp(this.configuration).findAllTransactions(page, size, sort, filterTypes, filterStatuses, filterCreatedAtFrom, filterCreatedAtTo, filterProcessedAtFrom, filterProcessedAtTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single transaction
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(id: number, options?: any) {
        return TransactionApiFp(this.configuration).getTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UISettingsApi - axios parameter creator
 * @export
 */
export const UISettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ui-settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication agent-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("agent-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication jumio required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("jumio", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication ondato required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("ondato", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UISettingsApi - functional programming interface
 * @export
 */
export const UISettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UiConfigurations>>> {
            const localVarAxiosArgs = await UISettingsApiAxiosParamCreator(configuration).getSettings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UISettingsApi - factory interface
 * @export
 */
export const UISettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<Array<UiConfigurations>> {
            return UISettingsApiFp(configuration).getSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UISettingsApi - object-oriented interface
 * @export
 * @class UISettingsApi
 * @extends {BaseAPI}
 */
export class UISettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UISettingsApi
     */
    public getSettings(options?: any) {
        return UISettingsApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

}


