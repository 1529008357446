/* eslint-disable no-underscore-dangle */
import Keycloak from 'keycloak-js';
import { action, computed, observable } from 'mobx';
import { Env } from 'src/core/Environment';
import { UiConfigurations } from 'src/generated-api-client';
import i18next, { initInternationalization } from 'src/i18n';
import { uiSettingsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/BasicStore/AsyncOperationWithStatus';
import { CookieHelper } from 'src/utils/CookieHelper';
import { RequestHelper } from 'src/utils/RequestHelper';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;
const KEYCLOAK_LOCALE = 'KEYCLOAK_LOCALE';

export class SettingsStoreClass {
    settingsLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(uiSettingsApi.getSettings()),
    );

    @observable currentLocale?: string;
    @observable currencyMnemonic?: string;

    @action async load() {
        const resp = await this.settingsLoader.call();

        if (!this.settingsLoader.hasError) {
            this.currencyMnemonic = resp?.find(
                (config) => config.appName === Env.appName,
            )?.env?.currencyMnemonic;
        }
    }

    @computed get settings(): UiConfigurations | undefined {
        return this.settingsLoader.data?.find(
            (config) => config.appName === Env.appName,
        );
    }

    @computed get hasSettings() {
        return this.settingsLoader.hasData;
    }

    @action setupKeycloak() {
        this._keycloack = new Keycloak({
            realm: this.settings?.authRealm!,
            clientId: Env.appName, // resource
            url: this.settings?.authServerUrl, // auth-server-url
        });

        this._keycloack.onTokenExpired = () => {
            this.updateTokenIfNeed();
        };
    }

    @action async updateTokenIfNeed() {
        await this.keycloak?.updateToken(UPDATE_TOKEN_MIN_VALIDITY);
    }

    @observable _keycloack?: Keycloak.KeycloakInstance;

    @computed get keycloak() {
        // eslint-disable-next-line no-underscore-dangle

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._keycloack!;
    }

    @action async setupInternationalization() {
        await initInternationalization({
            ...this.settings,
            defaultLocale:
                CookieHelper.getItem(KEYCLOAK_LOCALE) ||
                this.settings?.defaultLocale,
        } as any as any);
    }

    @computed get supportedLocales() {
        return this.settings?.supportedLocales || [];
    }

    @computed get hasFewLocales() {
        return this.supportedLocales.length > 1;
    }

    @action async setLocale(locale: string) {
        CookieHelper.setItem(KEYCLOAK_LOCALE, locale);
        await i18next.changeLanguage(locale);
    }
}

export const SettingsStore = new SettingsStoreClass();
